<div
  *ngIf="(tags && tags.length) || (projectTag)"
  [class.isDisableEdit]="isDisableEdit"
  class="tags-container"
>
  <div class="tags">
    <tag (click)="editTags()" *ngIf=" projectTag" [tag]="projectTag"></tag>
    <tag
      (click)="editTags()"
      *ngFor="let tag of tags; trackBy: trackByFn"
      [tag]="tag"
    ></tag>
  </div>
</div>
