<button
  (click)="isShowHelp =!isShowHelp;"
  aria-label="Show Help for section"
  class="help-btn mat-elevation-z2"
  color=""
  mat-mini-fab
  type="button"
>
  <mat-icon>{{isShowHelp ? 'close' : 'help_outline'}}</mat-icon>
</button>

<div *ngIf="isShowHelp" [@expandFade] class="help-text border-color-primary">
  <div class="help-icon-wrapper color-primary">
    <mat-icon>help_outline</mat-icon>
  </div>
  <ng-content></ng-content>
  <div class="help-icon-wrapper color-primary">
    <mat-icon>help_outline</mat-icon>
  </div>
</div>
