<div class="content mat-typography">
  <textarea #textareaEl [(ngModel)]="data.content" rows="1" tabindex="3"></textarea>
</div>

<div class="controls">
  <button
    (click)="close(true)"
    class="btn btn-primary submit-button"
    mat-button
    type="button"
  >
    <mat-icon>close</mat-icon>
    {{(T.G.CANCEL)|translate}}
  </button>

  <button
    (click)="close()"
    class="btn btn-primary submit-button"
    color="primary"
    mat-stroked-button
  >
    <mat-icon>check</mat-icon>
    {{(T.G.SAVE)|translate}}
  </button>
</div>
