const T = {
  APP: {
    B_INSTALL: {
      IGNORE: 'APP.B_INSTALL.IGNORE',
      INSTALL: 'APP.B_INSTALL.INSTALL',
      MSG: 'APP.B_INSTALL.MSG',
    },
    B_OFFLINE: 'APP.B_OFFLINE',
    D_INITIAL: {
      TITLE: 'APP.D_INITIAL.TITLE',
    },
    UPDATE_MAIN_MODEL: 'APP.UPDATE_MAIN_MODEL',
    UPDATE_MAIN_MODEL_NO_UPDATE: 'APP.UPDATE_MAIN_MODEL_NO_UPDATE',
    UPDATE_WEB_APP: 'APP.UPDATE_WEB_APP',
  },
  BL: {
    NO_TASKS: 'BL.NO_TASKS',
  },
  CONFIRM: {
    AUTO_FIX: 'CONFIRM.AUTO_FIX',
    DELETE_STRAY_BACKUP: 'CONFIRM.DELETE_STRAY_BACKUP',
    RESTORE_FILE_BACKUP: 'CONFIRM.RESTORE_FILE_BACKUP',
    RESTORE_STRAY_BACKUP: 'CONFIRM.RESTORE_STRAY_BACKUP',
  },
  DATETIME_INPUT: {
    IN: 'DATETIME_INPUT.IN',
    TOMORROW: 'DATETIME_INPUT.TOMORROW',
  },
  DATETIME_SCHEDULE: {
    LATER_TODAY: 'DATETIME_SCHEDULE.LATER_TODAY',
    NEXT_WEEK: 'DATETIME_SCHEDULE.NEXT_WEEK',
    PLACEHOLDER: 'DATETIME_SCHEDULE.PLACEHOLDER',
    PRESS_ENTER_AGAIN: 'DATETIME_SCHEDULE.PRESS_ENTER_AGAIN',
    TOMORROW: 'DATETIME_SCHEDULE.TOMORROW',
  },
  F: {
    ATTACHMENT: {
      DIALOG_EDIT: {
        ADD_ATTACHMENT: 'F.ATTACHMENT.DIALOG_EDIT.ADD_ATTACHMENT',
        EDIT_ATTACHMENT: 'F.ATTACHMENT.DIALOG_EDIT.EDIT_ATTACHMENT',
        LABELS: {
          FILE: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.FILE',
          IMG: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.IMG',
          LINK: 'F.ATTACHMENT.DIALOG_EDIT.LABELS.LINK',
        },
        SELECT_TYPE: 'F.ATTACHMENT.DIALOG_EDIT.SELECT_TYPE',
        TYPES: {
          FILE: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.FILE',
          IMG: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.IMG',
          LINK: 'F.ATTACHMENT.DIALOG_EDIT.TYPES.LINK',
        },
      },
    },
    BOOKMARK: {
      BAR: {
        ADD: 'F.BOOKMARK.BAR.ADD',
        DROP: 'F.BOOKMARK.BAR.DROP',
        EDIT: 'F.BOOKMARK.BAR.EDIT',
        NO_BOOKMARKS: 'F.BOOKMARK.BAR.NO_BOOKMARKS',
      },
      DIALOG_EDIT: {
        ADD_BOOKMARK: 'F.BOOKMARK.DIALOG_EDIT.ADD_BOOKMARK',
        EDIT_BOOKMARK: 'F.BOOKMARK.DIALOG_EDIT.EDIT_BOOKMARK',
        LABELS: {
          COMMAND: 'F.BOOKMARK.DIALOG_EDIT.LABELS.COMMAND',
          FILE: 'F.BOOKMARK.DIALOG_EDIT.LABELS.FILE',
          IMG: 'F.BOOKMARK.DIALOG_EDIT.LABELS.IMG',
          LINK: 'F.BOOKMARK.DIALOG_EDIT.LABELS.LINK',
        },
        SELECT_ICON: 'F.BOOKMARK.DIALOG_EDIT.SELECT_ICON',
        SELECT_TYPE: 'F.BOOKMARK.DIALOG_EDIT.SELECT_TYPE',
        TYPES: {
          COMMAND: 'F.BOOKMARK.DIALOG_EDIT.TYPES.COMMAND',
          FILE: 'F.BOOKMARK.DIALOG_EDIT.TYPES.FILE',
          IMG: 'F.BOOKMARK.DIALOG_EDIT.TYPES.IMG',
          LINK: 'F.BOOKMARK.DIALOG_EDIT.TYPES.LINK',
        },
      },
    },
    CALDAV: {
      DIALOG_INITIAL: {
        TITLE: 'F.CALDAV.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        CALDAV_CATEGORY_FILTER: 'F.CALDAV.FORM.CALDAV_CATEGORY_FILTER',
        CALDAV_PASSWORD: 'F.CALDAV.FORM.CALDAV_PASSWORD',
        CALDAV_RESOURCE: 'F.CALDAV.FORM.CALDAV_RESOURCE',
        CALDAV_URL: 'F.CALDAV.FORM.CALDAV_URL',
        CALDAV_USER: 'F.CALDAV.FORM.CALDAV_USER',
        IS_AUTO_ADD_TO_BACKLOG: 'F.CALDAV.FORM.IS_AUTO_ADD_TO_BACKLOG',
        IS_AUTO_POLL: 'F.CALDAV.FORM.IS_AUTO_POLL',
        IS_SEARCH_ISSUES_FROM_CALDAV: 'F.CALDAV.FORM.IS_SEARCH_ISSUES_FROM_CALDAV',
        IS_TRANSITION_ISSUES_ENABLED: 'F.CALDAV.FORM.IS_TRANSITION_ISSUES_ENABLED',
      },
      FORM_SECTION: {
        HELP: 'F.CALDAV.FORM_SECTION.HELP',
        TITLE: 'F.CALDAV.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        DESCRIPTION: 'F.CALDAV.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.CALDAV.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.CALDAV.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.CALDAV.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.CALDAV.ISSUE_CONTENT.SUMMARY',
      },
      S: {
        CALENDAR_NOT_FOUND: 'F.CALDAV.S.CALENDAR_NOT_FOUND',
        CALENDAR_READ_ONLY: 'F.CALDAV.S.CALENDAR_READ_ONLY',
        ERR_NETWORK: 'F.CALDAV.S.ERR_NETWORK',
        ERR_NOT_CONFIGURED: 'F.CALDAV.S.ERR_NOT_CONFIGURED',
        IMPORTED_MULTIPLE_ISSUES: 'F.CALDAV.S.IMPORTED_MULTIPLE_ISSUES',
        IMPORTED_SINGLE_ISSUE: 'F.CALDAV.S.IMPORTED_SINGLE_ISSUE',
        ISSUE_NOT_FOUND: 'F.CALDAV.S.ISSUE_NOT_FOUND',
        ISSUE_NO_UPDATE_REQUIRED: 'F.CALDAV.S.ISSUE_NO_UPDATE_REQUIRED',
        ISSUE_UPDATE: 'F.CALDAV.S.ISSUE_UPDATE',
        POLLING: 'F.CALDAV.S.POLLING',
      },
    },
    CONFIG: {
      S: {
        UPDATE_SECTION: 'F.CONFIG.S.UPDATE_SECTION',
      },
    },
    DROPBOX: {
      S: {
        ACCESS_TOKEN_ERROR: 'F.DROPBOX.S.ACCESS_TOKEN_ERROR',
        ACCESS_TOKEN_GENERATED: 'F.DROPBOX.S.ACCESS_TOKEN_GENERATED',
        AUTH_ERROR: 'F.DROPBOX.S.AUTH_ERROR',
        OFFLINE: 'F.DROPBOX.S.OFFLINE',
        SYNC_ERROR: 'F.DROPBOX.S.SYNC_ERROR',
      },
    },
    GITHUB: {
      DIALOG_INITIAL: {
        TITLE: 'F.GITHUB.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        FILTER_USER: 'F.GITHUB.FORM.FILTER_USER',
        IS_AUTO_ADD_TO_BACKLOG: 'F.GITHUB.FORM.IS_AUTO_ADD_TO_BACKLOG',
        IS_AUTO_POLL: 'F.GITHUB.FORM.IS_AUTO_POLL',
        IS_SEARCH_ISSUES_FROM_GITHUB: 'F.GITHUB.FORM.IS_SEARCH_ISSUES_FROM_GITHUB',
        REPO: 'F.GITHUB.FORM.REPO',
        TOKEN: 'F.GITHUB.FORM.TOKEN',
        TOKEN_DESCRIPTION: 'F.GITHUB.FORM.TOKEN_DESCRIPTION',
      },
      FORM_SECTION: {
        HELP: 'F.GITHUB.FORM_SECTION.HELP',
        TITLE: 'F.GITHUB.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.GITHUB.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.GITHUB.ISSUE_CONTENT.AT',
        DESCRIPTION: 'F.GITHUB.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.GITHUB.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.GITHUB.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.GITHUB.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.GITHUB.ISSUE_CONTENT.SUMMARY',
        WRITE_A_COMMENT: 'F.GITHUB.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ERR_NETWORK: 'F.GITHUB.S.ERR_NETWORK',
        ERR_NOT_CONFIGURED: 'F.GITHUB.S.ERR_NOT_CONFIGURED',
        ERR_UNKNOWN: 'F.GITHUB.S.ERR_UNKNOWN',
        IMPORTED_MULTIPLE_ISSUES: 'F.GITHUB.S.IMPORTED_MULTIPLE_ISSUES',
        IMPORTED_SINGLE_ISSUE: 'F.GITHUB.S.IMPORTED_SINGLE_ISSUE',
        ISSUE_DELETED_OR_CLOSED: 'F.GITHUB.S.ISSUE_DELETED_OR_CLOSED',
        ISSUE_NO_UPDATE_REQUIRED: 'F.GITHUB.S.ISSUE_NO_UPDATE_REQUIRED',
        ISSUE_UPDATE: 'F.GITHUB.S.ISSUE_UPDATE',
        MANUAL_UPDATE_ISSUE_SUCCESS: 'F.GITHUB.S.MANUAL_UPDATE_ISSUE_SUCCESS',
        MISSING_ISSUE_DATA: 'F.GITHUB.S.MISSING_ISSUE_DATA',
        NEW_COMMENT: 'F.GITHUB.S.NEW_COMMENT',
        POLLING: 'F.GITHUB.S.POLLING',
        SHOW_ISSUE_BTN: 'F.GITHUB.S.SHOW_ISSUE_BTN',
      },
    },
    GITLAB: {
      DIALOG_INITIAL: {
        TITLE: 'F.GITLAB.DIALOG_INITIAL.TITLE',
      },
      FORM: {
        FILTER_USER: 'F.GITLAB.FORM.FILTER_USER',
        GITLAB_BASE_URL: 'F.GITLAB.FORM.GITLAB_BASE_URL',
        IS_AUTO_ADD_TO_BACKLOG: 'F.GITLAB.FORM.IS_AUTO_ADD_TO_BACKLOG',
        IS_AUTO_POLL: 'F.GITLAB.FORM.IS_AUTO_POLL',
        IS_SEARCH_ISSUES_FROM_GITLAB: 'F.GITLAB.FORM.IS_SEARCH_ISSUES_FROM_GITLAB',
        PROJECT: 'F.GITLAB.FORM.PROJECT',
        TOKEN: 'F.GITLAB.FORM.TOKEN',
      },
      FORM_SECTION: {
        HELP: 'F.GITLAB.FORM_SECTION.HELP',
        TITLE: 'F.GITLAB.FORM_SECTION.TITLE',
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.GITLAB.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.GITLAB.ISSUE_CONTENT.AT',
        DESCRIPTION: 'F.GITLAB.ISSUE_CONTENT.DESCRIPTION',
        LABELS: 'F.GITLAB.ISSUE_CONTENT.LABELS',
        MARK_AS_CHECKED: 'F.GITLAB.ISSUE_CONTENT.MARK_AS_CHECKED',
        STATUS: 'F.GITLAB.ISSUE_CONTENT.STATUS',
        SUMMARY: 'F.GITLAB.ISSUE_CONTENT.SUMMARY',
        WRITE_A_COMMENT: 'F.GITLAB.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ERR_NETWORK: 'F.GITLAB.S.ERR_NETWORK',
        ERR_NOT_CONFIGURED: 'F.GITLAB.S.ERR_NOT_CONFIGURED',
        ERR_UNKNOWN: 'F.GITLAB.S.ERR_UNKNOWN',
        IMPORTED_MULTIPLE_ISSUES: 'F.GITLAB.S.IMPORTED_MULTIPLE_ISSUES',
        IMPORTED_SINGLE_ISSUE: 'F.GITLAB.S.IMPORTED_SINGLE_ISSUE',
        ISSUE_DELETED_OR_CLOSED: 'F.GITLAB.S.ISSUE_DELETED_OR_CLOSED',
        ISSUE_NO_UPDATE_REQUIRED: 'F.GITLAB.S.ISSUE_NO_UPDATE_REQUIRED',
        ISSUE_UPDATE: 'F.GITLAB.S.ISSUE_UPDATE',
        MANUAL_UPDATE_ISSUE_SUCCESS: 'F.GITLAB.S.MANUAL_UPDATE_ISSUE_SUCCESS',
        MISSING_ISSUE_DATA: 'F.GITLAB.S.MISSING_ISSUE_DATA',
        NEW_COMMENT: 'F.GITLAB.S.NEW_COMMENT',
        POLLING: 'F.GITLAB.S.POLLING',
        SHOW_ISSUE_BTN: 'F.GITLAB.S.SHOW_ISSUE_BTN',
      },
    },
    GOOGLE: {
      BANNER: {
        AUTH_FAIL: 'F.GOOGLE.BANNER.AUTH_FAIL',
      },
      DIALOG: {
        CREATE_SYNC_FILE: 'F.GOOGLE.DIALOG.CREATE_SYNC_FILE',
        USE_EXISTING_SYNC_FILE: 'F.GOOGLE.DIALOG.USE_EXISTING_SYNC_FILE',
      },
      S: {
        MULTIPLE_SYNC_FILES_WITH_SAME_NAME:
          'F.GOOGLE.S.MULTIPLE_SYNC_FILES_WITH_SAME_NAME',
        SYNC_FILE_CREATION_ERROR: 'F.GOOGLE.S.SYNC_FILE_CREATION_ERROR',
        UPDATED_SYNC_FILE_NAME: 'F.GOOGLE.S.UPDATED_SYNC_FILE_NAME',
      },
      S_API: {
        ERR: 'F.GOOGLE.S_API.ERR',
        ERR_NO_FILE_ID: 'F.GOOGLE.S_API.ERR_NO_FILE_ID',
        ERR_NO_FILE_NAME: 'F.GOOGLE.S_API.ERR_NO_FILE_NAME',
        SUCCESS_LOGIN: 'F.GOOGLE.S_API.SUCCESS_LOGIN',
      },
    },
    JIRA: {
      BANNER: {
        BLOCK_ACCESS_MSG: 'F.JIRA.BANNER.BLOCK_ACCESS_MSG',
        BLOCK_ACCESS_UNBLOCK: 'F.JIRA.BANNER.BLOCK_ACCESS_UNBLOCK',
      },
      CFG_CMP: {
        ALWAYS_ASK: 'F.JIRA.CFG_CMP.ALWAYS_ASK',
        DONE: 'F.JIRA.CFG_CMP.DONE',
        DO_NOT: 'F.JIRA.CFG_CMP.DO_NOT',
        ENABLE: 'F.JIRA.CFG_CMP.ENABLE',
        ENABLE_TRANSITIONS: 'F.JIRA.CFG_CMP.ENABLE_TRANSITIONS',
        IN_PROGRESS: 'F.JIRA.CFG_CMP.IN_PROGRESS',
        LOAD_SUGGESTIONS: 'F.JIRA.CFG_CMP.LOAD_SUGGESTIONS',
        MAP_CUSTOM_FIELDS: 'F.JIRA.CFG_CMP.MAP_CUSTOM_FIELDS',
        MAP_CUSTOM_FIELDS_INFO: 'F.JIRA.CFG_CMP.MAP_CUSTOM_FIELDS_INFO',
        OPEN: 'F.JIRA.CFG_CMP.OPEN',
        SELECT_ISSUE_FOR_TRANSITIONS: 'F.JIRA.CFG_CMP.SELECT_ISSUE_FOR_TRANSITIONS',
        STORY_POINTS: 'F.JIRA.CFG_CMP.STORY_POINTS',
      },
      DIALOG_CONFIRM_ASSIGNMENT: {
        MSG: 'F.JIRA.DIALOG_CONFIRM_ASSIGNMENT.MSG',
        OK: 'F.JIRA.DIALOG_CONFIRM_ASSIGNMENT.OK',
      },
      DIALOG_INITIAL: {
        TITLE: 'F.JIRA.DIALOG_INITIAL.TITLE',
      },
      DIALOG_TRANSITION: {
        CHOOSE_STATUS: 'F.JIRA.DIALOG_TRANSITION.CHOOSE_STATUS',
        CURRENT_ASSIGNEE: 'F.JIRA.DIALOG_TRANSITION.CURRENT_ASSIGNEE',
        CURRENT_STATUS: 'F.JIRA.DIALOG_TRANSITION.CURRENT_STATUS',
        TITLE: 'F.JIRA.DIALOG_TRANSITION.TITLE',
        UPDATE_STATUS: 'F.JIRA.DIALOG_TRANSITION.UPDATE_STATUS',
      },
      DIALOG_WORKLOG: {
        CURRENTLY_LOGGED: 'F.JIRA.DIALOG_WORKLOG.CURRENTLY_LOGGED',
        INVALID_DATE: 'F.JIRA.DIALOG_WORKLOG.INVALID_DATE',
        SAVE_WORKLOG: 'F.JIRA.DIALOG_WORKLOG.SAVE_WORKLOG',
        STARTED: 'F.JIRA.DIALOG_WORKLOG.STARTED',
        SUBMIT_WORKLOG_FOR: 'F.JIRA.DIALOG_WORKLOG.SUBMIT_WORKLOG_FOR',
        TIME_SPENT: 'F.JIRA.DIALOG_WORKLOG.TIME_SPENT',
        TITLE: 'F.JIRA.DIALOG_WORKLOG.TITLE',
      },
      FORM: {
        IS_AUTO_ADD_TO_BACKLOG: 'F.JIRA.FORM.IS_AUTO_ADD_TO_BACKLOG',
        IS_AUTO_POLL: 'F.JIRA.FORM.IS_AUTO_POLL',
        IS_SEARCH_ISSUES_FROM_GITHUB: 'F.JIRA.FORM.IS_SEARCH_ISSUES_FROM_GITHUB',
        REPO: 'F.JIRA.FORM.REPO',
      },
      FORM_ADV: {
        AUTO_ADD_BACKLOG_JQL_QUERY: 'F.JIRA.FORM_ADV.AUTO_ADD_BACKLOG_JQL_QUERY',
        IS_ADD_WORKLOG_ON_SUB_TASK_DONE:
          'F.JIRA.FORM_ADV.IS_ADD_WORKLOG_ON_SUB_TASK_DONE',
        IS_AUTO_ADD_TO_BACKLOG: 'F.JIRA.FORM_ADV.IS_AUTO_ADD_TO_BACKLOG',
        IS_AUTO_POLL_TICKETS: 'F.JIRA.FORM_ADV.IS_AUTO_POLL_TICKETS',
        IS_CHECK_TO_RE_ASSIGN_TICKET_ON_TASK_START:
          'F.JIRA.FORM_ADV.IS_CHECK_TO_RE_ASSIGN_TICKET_ON_TASK_START',
        IS_WORKLOG_ENABLED: 'F.JIRA.FORM_ADV.IS_WORKLOG_ENABLED',
        SEARCH_JQL_QUERY: 'F.JIRA.FORM_ADV.SEARCH_JQL_QUERY',
      },
      FORM_CRED: {
        ALLOW_SELF_SIGNED: 'F.JIRA.FORM_CRED.ALLOW_SELF_SIGNED',
        HOST: 'F.JIRA.FORM_CRED.HOST',
        PASSWORD: 'F.JIRA.FORM_CRED.PASSWORD',
        USER_NAME: 'F.JIRA.FORM_CRED.USER_NAME',
        WONKY_COOKIE_MODE: 'F.JIRA.FORM_CRED.WONKY_COOKIE_MODE',
      },
      FORM_SECTION: {
        ADV_CFG: 'F.JIRA.FORM_SECTION.ADV_CFG',
        CREDENTIALS: 'F.JIRA.FORM_SECTION.CREDENTIALS',
        HELP_ARR: {
          H1: 'F.JIRA.FORM_SECTION.HELP_ARR.H1',
          H2: 'F.JIRA.FORM_SECTION.HELP_ARR.H2',
          H3: 'F.JIRA.FORM_SECTION.HELP_ARR.H3',
          P1_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_1',
          P1_2: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_2',
          P1_3: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_3',
          P1_4: 'F.JIRA.FORM_SECTION.HELP_ARR.P1_4',
          P2_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_1',
          P2_2: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_2',
          P2_3: 'F.JIRA.FORM_SECTION.HELP_ARR.P2_3',
          P3_1: 'F.JIRA.FORM_SECTION.HELP_ARR.P3_1',
        },
      },
      ISSUE_CONTENT: {
        ASSIGNEE: 'F.JIRA.ISSUE_CONTENT.ASSIGNEE',
        AT: 'F.JIRA.ISSUE_CONTENT.AT',
        ATTACHMENTS: 'F.JIRA.ISSUE_CONTENT.ATTACHMENTS',
        CHANGED: 'F.JIRA.ISSUE_CONTENT.CHANGED',
        COMMENTS: 'F.JIRA.ISSUE_CONTENT.COMMENTS',
        COMPONENTS: 'F.JIRA.ISSUE_CONTENT.COMPONENTS',
        DESCRIPTION: 'F.JIRA.ISSUE_CONTENT.DESCRIPTION',
        LIST_OF_CHANGES: 'F.JIRA.ISSUE_CONTENT.LIST_OF_CHANGES',
        MARK_AS_CHECKED: 'F.JIRA.ISSUE_CONTENT.MARK_AS_CHECKED',
        ON: 'F.JIRA.ISSUE_CONTENT.ON',
        STATUS: 'F.JIRA.ISSUE_CONTENT.STATUS',
        STORY_POINTS: 'F.JIRA.ISSUE_CONTENT.STORY_POINTS',
        SUMMARY: 'F.JIRA.ISSUE_CONTENT.SUMMARY',
        WORKLOG: 'F.JIRA.ISSUE_CONTENT.WORKLOG',
        WRITE_A_COMMENT: 'F.JIRA.ISSUE_CONTENT.WRITE_A_COMMENT',
      },
      S: {
        ADDED_WORKLOG_FOR: 'F.JIRA.S.ADDED_WORKLOG_FOR',
        EXTENSION_NOT_LOADED: 'F.JIRA.S.EXTENSION_NOT_LOADED',
        IMPORTED_MULTIPLE_ISSUES: 'F.JIRA.S.IMPORTED_MULTIPLE_ISSUES',
        IMPORTED_SINGLE_ISSUE: 'F.JIRA.S.IMPORTED_SINGLE_ISSUE',
        INSUFFICIENT_SETTINGS: 'F.JIRA.S.INSUFFICIENT_SETTINGS',
        ISSUE_NO_UPDATE_REQUIRED: 'F.JIRA.S.ISSUE_NO_UPDATE_REQUIRED',
        ISSUE_UPDATE: 'F.JIRA.S.ISSUE_UPDATE',
        MANUAL_UPDATE_ISSUE_SUCCESS: 'F.JIRA.S.MANUAL_UPDATE_ISSUE_SUCCESS',
        MISSING_ISSUE_DATA: 'F.JIRA.S.MISSING_ISSUE_DATA',
        NO_AUTO_IMPORT_JQL: 'F.JIRA.S.NO_AUTO_IMPORT_JQL',
        NO_VALID_TRANSITION: 'F.JIRA.S.NO_VALID_TRANSITION',
        POLLING: 'F.JIRA.S.POLLING',
        TIMED_OUT: 'F.JIRA.S.TIMED_OUT',
        TRANSITION: 'F.JIRA.S.TRANSITION',
        TRANSITIONS_LOADED: 'F.JIRA.S.TRANSITIONS_LOADED',
        TRANSITION_SUCCESS: 'F.JIRA.S.TRANSITION_SUCCESS',
        UNABLE_TO_REASSIGN: 'F.JIRA.S.UNABLE_TO_REASSIGN',
      },
      STEPPER: {
        CREDENTIALS: 'F.JIRA.STEPPER.CREDENTIALS',
        DONE: 'F.JIRA.STEPPER.DONE',
        LOGIN_SUCCESS: 'F.JIRA.STEPPER.LOGIN_SUCCESS',
        TEST_CREDENTIALS: 'F.JIRA.STEPPER.TEST_CREDENTIALS',
        WELCOME_USER: 'F.JIRA.STEPPER.WELCOME_USER',
      },
    },
    METRIC: {
      BANNER: {
        CHECK: 'F.METRIC.BANNER.CHECK',
      },
      CMP: {
        AVG_BREAKS_PER_DAY: 'F.METRIC.CMP.AVG_BREAKS_PER_DAY',
        AVG_TASKS_PER_DAY_WORKED: 'F.METRIC.CMP.AVG_TASKS_PER_DAY_WORKED',
        AVG_TIME_SPENT_ON_BREAKS: 'F.METRIC.CMP.AVG_TIME_SPENT_ON_BREAKS',
        AVG_TIME_SPENT_PER_DAY: 'F.METRIC.CMP.AVG_TIME_SPENT_PER_DAY',
        AVG_TIME_SPENT_PER_TASK: 'F.METRIC.CMP.AVG_TIME_SPENT_PER_TASK',
        COUNTING_SUBTASKS: 'F.METRIC.CMP.COUNTING_SUBTASKS',
        DAYS_WORKED: 'F.METRIC.CMP.DAYS_WORKED',
        GLOBAL_METRICS: 'F.METRIC.CMP.GLOBAL_METRICS',
        IMPROVEMENT_SELECTION_COUNT: 'F.METRIC.CMP.IMPROVEMENT_SELECTION_COUNT',
        MOOD_PRODUCTIVITY_OVER_TIME: 'F.METRIC.CMP.MOOD_PRODUCTIVITY_OVER_TIME',
        NO_ADDITIONAL_DATA_YET: 'F.METRIC.CMP.NO_ADDITIONAL_DATA_YET',
        OBSTRUCTION_SELECTION_COUNT: 'F.METRIC.CMP.OBSTRUCTION_SELECTION_COUNT',
        TASKS_DONE_CREATED: 'F.METRIC.CMP.TASKS_DONE_CREATED',
        TIME_ESTIMATED: 'F.METRIC.CMP.TIME_ESTIMATED',
        TIME_SPENT: 'F.METRIC.CMP.TIME_SPENT',
      },
      EVAL_FORM: {
        ADD_NOTE_FOR_TOMORROW: 'F.METRIC.EVAL_FORM.ADD_NOTE_FOR_TOMORROW',
        DISABLE_REPEAT_EVERY_DAY: 'F.METRIC.EVAL_FORM.DISABLE_REPEAT_EVERY_DAY',
        ENABLE_REPEAT_EVERY_DAY: 'F.METRIC.EVAL_FORM.ENABLE_REPEAT_EVERY_DAY',
        HELP_H1: 'F.METRIC.EVAL_FORM.HELP_H1',
        HELP_LINK_TXT: 'F.METRIC.EVAL_FORM.HELP_LINK_TXT',
        HELP_P1: 'F.METRIC.EVAL_FORM.HELP_P1',
        HELP_P2: 'F.METRIC.EVAL_FORM.HELP_P2',
        IMPROVEMENTS: 'F.METRIC.EVAL_FORM.IMPROVEMENTS',
        IMPROVEMENTS_TOMORROW: 'F.METRIC.EVAL_FORM.IMPROVEMENTS_TOMORROW',
        MOOD: 'F.METRIC.EVAL_FORM.MOOD',
        MOOD_HINT: 'F.METRIC.EVAL_FORM.MOOD_HINT',
        NOTES: 'F.METRIC.EVAL_FORM.NOTES',
        OBSTRUCTIONS: 'F.METRIC.EVAL_FORM.OBSTRUCTIONS',
        PRODUCTIVITY: 'F.METRIC.EVAL_FORM.PRODUCTIVITY',
        PRODUCTIVITY_HINT: 'F.METRIC.EVAL_FORM.PRODUCTIVITY_HINT',
      },
      S: {
        SAVE_METRIC: 'F.METRIC.S.SAVE_METRIC',
      },
    },
    NOTE: {
      ADD_REMINDER: 'F.NOTE.ADD_REMINDER',
      D_ADD: {
        DATETIME_LABEL: 'F.NOTE.D_ADD.DATETIME_LABEL',
        NOTE_LABEL: 'F.NOTE.D_ADD.NOTE_LABEL',
      },
      D_ADD_REMINDER: {
        E_ENTER_TITLE: 'F.NOTE.D_ADD_REMINDER.E_ENTER_TITLE',
        L_DATETIME: 'F.NOTE.D_ADD_REMINDER.L_DATETIME',
        L_TITLE: 'F.NOTE.D_ADD_REMINDER.L_TITLE',
      },
      D_VIEW_REMINDER: {
        SNOOZE: 'F.NOTE.D_VIEW_REMINDER.SNOOZE',
        TITLE: 'F.NOTE.D_VIEW_REMINDER.TITLE',
      },
      EDIT_FULLSCREEN: 'F.NOTE.EDIT_FULLSCREEN',
      EDIT_REMINDER: 'F.NOTE.EDIT_REMINDER',
      NOTES_CMP: {
        ADD_BTN: 'F.NOTE.NOTES_CMP.ADD_BTN',
        DROP_TO_ADD: 'F.NOTE.NOTES_CMP.DROP_TO_ADD',
      },
      NOTE_CMP: {
        DISABLE_PARSE: 'F.NOTE.NOTE_CMP.DISABLE_PARSE',
        ENABLE_PARSE: 'F.NOTE.NOTE_CMP.ENABLE_PARSE',
      },
      REMOVE_REMINDER: 'F.NOTE.REMOVE_REMINDER',
      S: {
        ADDED_REMINDER: 'F.NOTE.S.ADDED_REMINDER',
        DELETED_REMINDER: 'F.NOTE.S.DELETED_REMINDER',
        UPDATED_REMINDER: 'F.NOTE.S.UPDATED_REMINDER',
      },
      UPDATE_REMINDER: 'F.NOTE.UPDATE_REMINDER',
    },
    POMODORO: {
      BACK_TO_WORK: 'F.POMODORO.BACK_TO_WORK',
      BREAK_IS_DONE: 'F.POMODORO.BREAK_IS_DONE',
      ENJOY_YOURSELF: 'F.POMODORO.ENJOY_YOURSELF',
      FINISH_SESSION_X: 'F.POMODORO.FINISH_SESSION_X',
      NOTIFICATION: {
        BREAK_X_START: 'F.POMODORO.NOTIFICATION.BREAK_X_START',
        SESSION_X_START: 'F.POMODORO.NOTIFICATION.SESSION_X_START',
      },
      S: {
        SESSION_X_START: 'F.POMODORO.S.SESSION_X_START',
      },
      SKIP_BREAK: 'F.POMODORO.SKIP_BREAK',
    },
    PROCRASTINATION: {
      BACK_TO_WORK: 'F.PROCRASTINATION.BACK_TO_WORK',
      COMP: {
        INTRO: 'F.PROCRASTINATION.COMP.INTRO',
        L1: 'F.PROCRASTINATION.COMP.L1',
        L2: 'F.PROCRASTINATION.COMP.L2',
        L3: 'F.PROCRASTINATION.COMP.L3',
        L4: 'F.PROCRASTINATION.COMP.L4',
        OUTRO: 'F.PROCRASTINATION.COMP.OUTRO',
        TITLE: 'F.PROCRASTINATION.COMP.TITLE',
      },
      CUR: {
        INTRO: 'F.PROCRASTINATION.CUR.INTRO',
        L1: 'F.PROCRASTINATION.CUR.L1',
        L2: 'F.PROCRASTINATION.CUR.L2',
        L3: 'F.PROCRASTINATION.CUR.L3',
        L4: 'F.PROCRASTINATION.CUR.L4',
        L5: 'F.PROCRASTINATION.CUR.L5',
        TITLE: 'F.PROCRASTINATION.CUR.TITLE',
      },
      H1: 'F.PROCRASTINATION.H1',
      P1: 'F.PROCRASTINATION.P1',
      P2: 'F.PROCRASTINATION.P2',
      P3: 'F.PROCRASTINATION.P3',
      REFRAME: {
        INTRO: 'F.PROCRASTINATION.REFRAME.INTRO',
        TITLE: 'F.PROCRASTINATION.REFRAME.TITLE',
        TL1: 'F.PROCRASTINATION.REFRAME.TL1',
        TL2: 'F.PROCRASTINATION.REFRAME.TL2',
        TL3: 'F.PROCRASTINATION.REFRAME.TL3',
      },
      SPLIT_UP: {
        INTRO: 'F.PROCRASTINATION.SPLIT_UP.INTRO',
        OUTRO: 'F.PROCRASTINATION.SPLIT_UP.OUTRO',
        TITLE: 'F.PROCRASTINATION.SPLIT_UP.TITLE',
      },
    },
    PROJECT: {
      D_CREATE: {
        CREATE: 'F.PROJECT.D_CREATE.CREATE',
        EDIT: 'F.PROJECT.D_CREATE.EDIT',
        SETUP_CALDAV: 'F.PROJECT.D_CREATE.SETUP_CALDAV',
        SETUP_GIT: 'F.PROJECT.D_CREATE.SETUP_GIT',
        SETUP_GITLAB: 'F.PROJECT.D_CREATE.SETUP_GITLAB',
        SETUP_JIRA: 'F.PROJECT.D_CREATE.SETUP_JIRA',
      },
      FORM_BASIC: {
        L_TITLE: 'F.PROJECT.FORM_BASIC.L_TITLE',
        TITLE: 'F.PROJECT.FORM_BASIC.TITLE',
      },
      FORM_THEME: {
        D_IS_DARK_THEME: 'F.PROJECT.FORM_THEME.D_IS_DARK_THEME',
        HELP: 'F.PROJECT.FORM_THEME.HELP',
        L_BACKGROUND_IMAGE_DARK: 'F.PROJECT.FORM_THEME.L_BACKGROUND_IMAGE_DARK',
        L_BACKGROUND_IMAGE_LIGHT: 'F.PROJECT.FORM_THEME.L_BACKGROUND_IMAGE_LIGHT',
        L_COLOR_ACCENT: 'F.PROJECT.FORM_THEME.L_COLOR_ACCENT',
        L_COLOR_PRIMARY: 'F.PROJECT.FORM_THEME.L_COLOR_PRIMARY',
        L_COLOR_WARN: 'F.PROJECT.FORM_THEME.L_COLOR_WARN',
        L_HUE_ACCENT: 'F.PROJECT.FORM_THEME.L_HUE_ACCENT',
        L_HUE_PRIMARY: 'F.PROJECT.FORM_THEME.L_HUE_PRIMARY',
        L_HUE_WARN: 'F.PROJECT.FORM_THEME.L_HUE_WARN',
        L_IS_AUTO_CONTRAST: 'F.PROJECT.FORM_THEME.L_IS_AUTO_CONTRAST',
        L_IS_DISABLE_BACKGROUND_GRADIENT:
          'F.PROJECT.FORM_THEME.L_IS_DISABLE_BACKGROUND_GRADIENT',
        L_IS_REDUCED_THEME: 'F.PROJECT.FORM_THEME.L_IS_REDUCED_THEME',
        L_THEME_COLOR: 'F.PROJECT.FORM_THEME.L_THEME_COLOR',
        L_TITLE: 'F.PROJECT.FORM_THEME.L_TITLE',
        TITLE: 'F.PROJECT.FORM_THEME.TITLE',
      },
      S: {
        ARCHIVED: 'F.PROJECT.S.ARCHIVED',
        CREATED: 'F.PROJECT.S.CREATED',
        DELETED: 'F.PROJECT.S.DELETED',
        E_EXISTS: 'F.PROJECT.S.E_EXISTS',
        E_INVALID_FILE: 'F.PROJECT.S.E_INVALID_FILE',
        ISSUE_PROVIDER_UPDATED: 'F.PROJECT.S.ISSUE_PROVIDER_UPDATED',
        UNARCHIVED: 'F.PROJECT.S.UNARCHIVED',
        UPDATED: 'F.PROJECT.S.UPDATED',
      },
    },
    REMINDER: {
      S_REMINDER_ERR: 'F.REMINDER.S_REMINDER_ERR',
    },
    SIMPLE_COUNTER: {
      D_CONFIRM_REMOVE: {
        MSG: 'F.SIMPLE_COUNTER.D_CONFIRM_REMOVE.MSG',
        OK: 'F.SIMPLE_COUNTER.D_CONFIRM_REMOVE.OK',
      },
      D_EDIT: {
        L_COUNTER: 'F.SIMPLE_COUNTER.D_EDIT.L_COUNTER',
        TITLE: 'F.SIMPLE_COUNTER.D_EDIT.TITLE',
      },
      FORM: {
        ADD_NEW: 'F.SIMPLE_COUNTER.FORM.ADD_NEW',
        HELP: 'F.SIMPLE_COUNTER.FORM.HELP',
        L_AUTO_COUNT_UP: 'F.SIMPLE_COUNTER.FORM.L_AUTO_COUNT_UP',
        L_AUTO_SWITCH_OFF: 'F.SIMPLE_COUNTER.FORM.L_AUTO_SWITCH_OFF',
        L_AUTO_SWITCH_ON: 'F.SIMPLE_COUNTER.FORM.L_AUTO_SWITCH_ON',
        L_ICON: 'F.SIMPLE_COUNTER.FORM.L_ICON',
        L_ICON_ON: 'F.SIMPLE_COUNTER.FORM.L_ICON_ON',
        L_IS_ENABLED: 'F.SIMPLE_COUNTER.FORM.L_IS_ENABLED',
        L_TITLE: 'F.SIMPLE_COUNTER.FORM.L_TITLE',
        L_TYPE: 'F.SIMPLE_COUNTER.FORM.L_TYPE',
        TITLE: 'F.SIMPLE_COUNTER.FORM.TITLE',
        TYPE_CLICK_COUNTER: 'F.SIMPLE_COUNTER.FORM.TYPE_CLICK_COUNTER',
        TYPE_STOPWATCH: 'F.SIMPLE_COUNTER.FORM.TYPE_STOPWATCH',
      },
    },
    SYNC: {
      C: {
        EMPTY_SYNC: 'F.SYNC.C.EMPTY_SYNC',
        FORCE_IMPORT: 'F.SYNC.C.FORCE_IMPORT',
        FORCE_UPLOAD: 'F.SYNC.C.FORCE_UPLOAD',
        FORCE_UPLOAD_AFTER_ERROR: 'F.SYNC.C.FORCE_UPLOAD_AFTER_ERROR',
        NO_REMOTE_DATA: 'F.SYNC.C.NO_REMOTE_DATA',
        TRY_LOAD_REMOTE_AGAIN: 'F.SYNC.C.TRY_LOAD_REMOTE_AGAIN',
      },
      D_AUTH_CODE: {
        FOLLOW_LINK: 'F.SYNC.D_AUTH_CODE.FOLLOW_LINK',
        GET_AUTH_CODE: 'F.SYNC.D_AUTH_CODE.GET_AUTH_CODE',
        L_AUTH_CODE: 'F.SYNC.D_AUTH_CODE.L_AUTH_CODE',
        TITLE: 'F.SYNC.D_AUTH_CODE.TITLE',
      },
      D_CONFLICT: {
        LAST_CHANGE: 'F.SYNC.D_CONFLICT.LAST_CHANGE',
        LAST_SYNC: 'F.SYNC.D_CONFLICT.LAST_SYNC',
        LOCAL: 'F.SYNC.D_CONFLICT.LOCAL',
        LOCAL_REMOTE: 'F.SYNC.D_CONFLICT.LOCAL_REMOTE',
        REMOTE: 'F.SYNC.D_CONFLICT.REMOTE',
        TEXT: 'F.SYNC.D_CONFLICT.TEXT',
        TITLE: 'F.SYNC.D_CONFLICT.TITLE',
        USE_LOCAL: 'F.SYNC.D_CONFLICT.USE_LOCAL',
        USE_REMOTE: 'F.SYNC.D_CONFLICT.USE_REMOTE',
      },
      FORM: {
        DROPBOX: {
          B_GENERATE_TOKEN: 'F.SYNC.FORM.DROPBOX.B_GENERATE_TOKEN',
          FOLLOW_LINK: 'F.SYNC.FORM.DROPBOX.FOLLOW_LINK',
          L_ACCESS_TOKEN: 'F.SYNC.FORM.DROPBOX.L_ACCESS_TOKEN',
          L_AUTH_CODE: 'F.SYNC.FORM.DROPBOX.L_AUTH_CODE',
        },
        GOOGLE: {
          L_IS_COMPRESS_DATA: 'F.SYNC.FORM.GOOGLE.L_IS_COMPRESS_DATA',
          L_SYNC_FILE_NAME: 'F.SYNC.FORM.GOOGLE.L_SYNC_FILE_NAME',
        },
        L_ENABLE_SYNCING: 'F.SYNC.FORM.L_ENABLE_SYNCING',
        L_SYNC_INTERVAL: 'F.SYNC.FORM.L_SYNC_INTERVAL',
        L_SYNC_PROVIDER: 'F.SYNC.FORM.L_SYNC_PROVIDER',
        TITLE: 'F.SYNC.FORM.TITLE',
        WEB_DAV: {
          CORS_INFO: 'F.SYNC.FORM.WEB_DAV.CORS_INFO',
          L_BASE_URL: 'F.SYNC.FORM.WEB_DAV.L_BASE_URL',
          L_PASSWORD: 'F.SYNC.FORM.WEB_DAV.L_PASSWORD',
          L_SYNC_FILE_PATH: 'F.SYNC.FORM.WEB_DAV.L_SYNC_FILE_PATH',
          L_USER_NAME: 'F.SYNC.FORM.WEB_DAV.L_USER_NAME',
        },
      },
      S: {
        ERROR_FALLBACK_TO_BACKUP: 'F.SYNC.S.ERROR_FALLBACK_TO_BACKUP',
        ERROR_INVALID_DATA: 'F.SYNC.S.ERROR_INVALID_DATA',
        IMPORTING: 'F.SYNC.S.IMPORTING',
        INCOMPLETE_CFG: 'F.SYNC.S.INCOMPLETE_CFG',
        INITIAL_SYNC_ERROR: 'F.SYNC.S.INITIAL_SYNC_ERROR',
        SUCCESS: 'F.SYNC.S.SUCCESS',
        UNKNOWN_ERROR: 'F.SYNC.S.UNKNOWN_ERROR',
        UPLOAD_ERROR: 'F.SYNC.S.UPLOAD_ERROR',
      },
    },
    TAG: {
      D_CREATE: {
        CREATE: 'F.TAG.D_CREATE.CREATE',
        EDIT: 'F.TAG.D_CREATE.EDIT',
      },
      D_DELETE: {
        CONFIRM_MSG: 'F.TAG.D_DELETE.CONFIRM_MSG',
      },
      D_EDIT: {
        ADD: 'F.TAG.D_EDIT.ADD',
        EDIT: 'F.TAG.D_EDIT.EDIT',
        LABEL: 'F.TAG.D_EDIT.LABEL',
      },
      FORM_BASIC: {
        L_COLOR: 'F.TAG.FORM_BASIC.L_COLOR',
        L_ICON: 'F.TAG.FORM_BASIC.L_ICON',
        L_TITLE: 'F.TAG.FORM_BASIC.L_TITLE',
        TITLE: 'F.TAG.FORM_BASIC.TITLE',
      },
      S: {
        UPDATED: 'F.TAG.S.UPDATED',
      },
    },
    TASK: {
      ADDITIONAL_INFO: {
        ADD_ATTACHMENT: 'F.TASK.ADDITIONAL_INFO.ADD_ATTACHMENT',
        ADD_SUB_TASK: 'F.TASK.ADDITIONAL_INFO.ADD_SUB_TASK',
        ATTACHMENTS: 'F.TASK.ADDITIONAL_INFO.ATTACHMENTS',
        FROM_PARENT: 'F.TASK.ADDITIONAL_INFO.FROM_PARENT',
        LOCAL_ATTACHMENTS: 'F.TASK.ADDITIONAL_INFO.LOCAL_ATTACHMENTS',
        NOTES: 'F.TASK.ADDITIONAL_INFO.NOTES',
        PARENT: 'F.TASK.ADDITIONAL_INFO.PARENT',
        REMINDER: 'F.TASK.ADDITIONAL_INFO.REMINDER',
        REPEAT: 'F.TASK.ADDITIONAL_INFO.REPEAT',
        SCHEDULE_TASK: 'F.TASK.ADDITIONAL_INFO.SCHEDULE_TASK',
        SUB_TASKS: 'F.TASK.ADDITIONAL_INFO.SUB_TASKS',
        TIME: 'F.TASK.ADDITIONAL_INFO.TIME',
      },
      ADD_TASK_BAR: {
        ADD_EXISTING_TASK: 'F.TASK.ADD_TASK_BAR.ADD_EXISTING_TASK',
        ADD_ISSUE_TASK: 'F.TASK.ADD_TASK_BAR.ADD_ISSUE_TASK',
        ADD_TASK: 'F.TASK.ADD_TASK_BAR.ADD_TASK',
        ADD_TASK_TO_BACKLOG: 'F.TASK.ADD_TASK_BAR.ADD_TASK_TO_BACKLOG',
        CREATE_TASK: 'F.TASK.ADD_TASK_BAR.CREATE_TASK',
        EXAMPLE: 'F.TASK.ADD_TASK_BAR.EXAMPLE',
        START: 'F.TASK.ADD_TASK_BAR.START',
      },
      B: {
        ADD_HALF_HOUR: 'F.TASK.B.ADD_HALF_HOUR',
        ESTIMATE_EXCEEDED: 'F.TASK.B.ESTIMATE_EXCEEDED',
      },
      CMP: {
        ADD_SUB_TASK: 'F.TASK.CMP.ADD_SUB_TASK',
        ADD_TO_MY_DAY: 'F.TASK.CMP.ADD_TO_MY_DAY',
        ADD_TO_PROJECT: 'F.TASK.CMP.ADD_TO_PROJECT',
        CONVERT_TO_PARENT_TASK: 'F.TASK.CMP.CONVERT_TO_PARENT_TASK',
        DELETE: 'F.TASK.CMP.DELETE',
        DROP_ATTACHMENT: 'F.TASK.CMP.DROP_ATTACHMENT',
        EDIT_REMINDER: 'F.TASK.CMP.EDIT_REMINDER',
        EDIT_TAGS: 'F.TASK.CMP.EDIT_TAGS',
        MARK_DONE: 'F.TASK.CMP.MARK_DONE',
        MARK_UNDONE: 'F.TASK.CMP.MARK_UNDONE',
        MOVE_TO_BACKLOG: 'F.TASK.CMP.MOVE_TO_BACKLOG',
        MOVE_TO_OTHER_PROJECT: 'F.TASK.CMP.MOVE_TO_OTHER_PROJECT',
        MOVE_TO_TODAY: 'F.TASK.CMP.MOVE_TO_TODAY',
        OPEN_ATTACH: 'F.TASK.CMP.OPEN_ATTACH',
        OPEN_ISSUE: 'F.TASK.CMP.OPEN_ISSUE',
        OPEN_TIME: 'F.TASK.CMP.OPEN_TIME',
        REMOVE_FROM_MY_DAY: 'F.TASK.CMP.REMOVE_FROM_MY_DAY',
        REPEAT_EDIT: 'F.TASK.CMP.REPEAT_EDIT',
        SCHEDULE: 'F.TASK.CMP.SCHEDULE',
        SHOW_UPDATES: 'F.TASK.CMP.SHOW_UPDATES',
        TOGGLE_ADDITIONAL: 'F.TASK.CMP.TOGGLE_ADDITIONAL',
        TOGGLE_ATTACHMENTS: 'F.TASK.CMP.TOGGLE_ATTACHMENTS',
        TOGGLE_DONE: 'F.TASK.CMP.TOGGLE_DONE',
        TOGGLE_SUB_TASK_VISIBILITY: 'F.TASK.CMP.TOGGLE_SUB_TASK_VISIBILITY',
        TRACK_TIME: 'F.TASK.CMP.TRACK_TIME',
        TRACK_TIME_STOP: 'F.TASK.CMP.TRACK_TIME_STOP',
        UPDATE_ISSUE_DATA: 'F.TASK.CMP.UPDATE_ISSUE_DATA',
      },
      D_REMINDER_ADD: {
        DATETIME_FOR: 'F.TASK.D_REMINDER_ADD.DATETIME_FOR',
        EDIT: 'F.TASK.D_REMINDER_ADD.EDIT',
        MOVE_TO_BACKLOG: 'F.TASK.D_REMINDER_ADD.MOVE_TO_BACKLOG',
        REMIND_AT: 'F.TASK.D_REMINDER_ADD.REMIND_AT',
        RO_10M: 'F.TASK.D_REMINDER_ADD.RO_10M',
        RO_15M: 'F.TASK.D_REMINDER_ADD.RO_15M',
        RO_1H: 'F.TASK.D_REMINDER_ADD.RO_1H',
        RO_30M: 'F.TASK.D_REMINDER_ADD.RO_30M',
        RO_5M: 'F.TASK.D_REMINDER_ADD.RO_5M',
        RO_START: 'F.TASK.D_REMINDER_ADD.RO_START',
        SCHEDULE: 'F.TASK.D_REMINDER_ADD.SCHEDULE',
        UNSCHEDULE: 'F.TASK.D_REMINDER_ADD.UNSCHEDULE',
      },
      D_REMINDER_VIEW: {
        ADD_ALL_TO_TODAY: 'F.TASK.D_REMINDER_VIEW.ADD_ALL_TO_TODAY',
        ADD_TO_TODAY: 'F.TASK.D_REMINDER_VIEW.ADD_TO_TODAY',
        DISMISS: 'F.TASK.D_REMINDER_VIEW.DISMISS',
        DISMISS_ALL: 'F.TASK.D_REMINDER_VIEW.DISMISS_ALL',
        DUE_TASK: 'F.TASK.D_REMINDER_VIEW.DUE_TASK',
        DUE_TASKS: 'F.TASK.D_REMINDER_VIEW.DUE_TASKS',
        FOR_CURRENT: 'F.TASK.D_REMINDER_VIEW.FOR_CURRENT',
        FOR_OTHER: 'F.TASK.D_REMINDER_VIEW.FOR_OTHER',
        FROM_PROJECT: 'F.TASK.D_REMINDER_VIEW.FROM_PROJECT',
        FROM_TAG: 'F.TASK.D_REMINDER_VIEW.FROM_TAG',
        SNOOZE: 'F.TASK.D_REMINDER_VIEW.SNOOZE',
        SNOOZE_ALL: 'F.TASK.D_REMINDER_VIEW.SNOOZE_ALL',
        RESCHEDULE_UNTIL_TOMORROW: 'F.TASK.D_REMINDER_VIEW.RESCHEDULE_UNTIL_TOMORROW',
        START: 'F.TASK.D_REMINDER_VIEW.START',
        SWITCH_CONTEXT_START: 'F.TASK.D_REMINDER_VIEW.SWITCH_CONTEXT_START',
      },
      D_TIME: {
        ADD_FOR_OTHER_DAY: 'F.TASK.D_TIME.ADD_FOR_OTHER_DAY',
        DELETE_FOR: 'F.TASK.D_TIME.DELETE_FOR',
        ESTIMATE: 'F.TASK.D_TIME.ESTIMATE',
        TIME_SPENT: 'F.TASK.D_TIME.TIME_SPENT',
        TIME_SPENT_ON: 'F.TASK.D_TIME.TIME_SPENT_ON',
        TITLE: 'F.TASK.D_TIME.TITLE',
      },
      D_TIME_FOR_DAY: {
        ADD_ENTRY_FOR: 'F.TASK.D_TIME_FOR_DAY.ADD_ENTRY_FOR',
        DATE: 'F.TASK.D_TIME_FOR_DAY.DATE',
        HELP: 'F.TASK.D_TIME_FOR_DAY.HELP',
        TINE_SPENT: 'F.TASK.D_TIME_FOR_DAY.TINE_SPENT',
        TITLE: 'F.TASK.D_TIME_FOR_DAY.TITLE',
      },
      N: {
        ESTIMATE_EXCEEDED: 'F.TASK.N.ESTIMATE_EXCEEDED',
        ESTIMATE_EXCEEDED_BODY: 'F.TASK.N.ESTIMATE_EXCEEDED_BODY',
      },
      S: {
        DELETED: 'F.TASK.S.DELETED',
        FOUND_MOVE_FROM_BACKLOG: 'F.TASK.S.FOUND_MOVE_FROM_BACKLOG',
        FOUND_MOVE_FROM_OTHER_LIST: 'F.TASK.S.FOUND_MOVE_FROM_OTHER_LIST',
        FOUND_RESTORE_FROM_ARCHIVE: 'F.TASK.S.FOUND_RESTORE_FROM_ARCHIVE',
        LAST_TAG_DELETION_WARNING: 'F.TASK.S.LAST_TAG_DELETION_WARNING',
        REMINDER_ADDED: 'F.TASK.S.REMINDER_ADDED',
        REMINDER_DELETED: 'F.TASK.S.REMINDER_DELETED',
        REMINDER_UPDATED: 'F.TASK.S.REMINDER_UPDATED',
        TASK_CREATED: 'F.TASK.S.TASK_CREATED',
      },
      SELECT_OR_CREATE: 'F.TASK.SELECT_OR_CREATE',
      SUMMARY_TABLE: {
        ESTIMATE: 'F.TASK.SUMMARY_TABLE.ESTIMATE',
        SPENT_TODAY: 'F.TASK.SUMMARY_TABLE.SPENT_TODAY',
        SPENT_TOTAL: 'F.TASK.SUMMARY_TABLE.SPENT_TOTAL',
        TASK: 'F.TASK.SUMMARY_TABLE.TASK',
        TOGGLE_DONE: 'F.TASK.SUMMARY_TABLE.TOGGLE_DONE',
      },
    },
    TASK_REPEAT: {
      D_CONFIRM_REMOVE: {
        MSG: 'F.TASK_REPEAT.D_CONFIRM_REMOVE.MSG',
        OK: 'F.TASK_REPEAT.D_CONFIRM_REMOVE.OK',
      },
      D_EDIT: {
        ADD: 'F.TASK_REPEAT.D_EDIT.ADD',
        EDIT: 'F.TASK_REPEAT.D_EDIT.EDIT',
        HELP1: 'F.TASK_REPEAT.D_EDIT.HELP1',
        HELP2: 'F.TASK_REPEAT.D_EDIT.HELP2',
        HELP3: 'F.TASK_REPEAT.D_EDIT.HELP3',
        TAG_LABEL: 'F.TASK_REPEAT.D_EDIT.TAG_LABEL',
      },
      F: {
        DEFAULT_ESTIMATE: 'F.TASK_REPEAT.F.DEFAULT_ESTIMATE',
        FRIDAY: 'F.TASK_REPEAT.F.FRIDAY',
        IS_ADD_TO_BOTTOM: 'F.TASK_REPEAT.F.IS_ADD_TO_BOTTOM',
        MONDAY: 'F.TASK_REPEAT.F.MONDAY',
        SATURDAY: 'F.TASK_REPEAT.F.SATURDAY',
        SUNDAY: 'F.TASK_REPEAT.F.SUNDAY',
        THURSDAY: 'F.TASK_REPEAT.F.THURSDAY',
        TITLE: 'F.TASK_REPEAT.F.TITLE',
        TUESDAY: 'F.TASK_REPEAT.F.TUESDAY',
        WEDNESDAY: 'F.TASK_REPEAT.F.WEDNESDAY',
      },
    },
    TIMELINE: {
      CONTINUED: 'F.TIMELINE.CONTINUED',
      D_INITIAL: {
        TEXT: 'F.TIMELINE.D_INITIAL.TEXT',
        TITLE: 'F.TIMELINE.D_INITIAL.TITLE',
      },
      END: 'F.TIMELINE.END',
      MENU_TITLE: 'F.TIMELINE.MENU_TITLE',
      NOW: 'F.TIMELINE.NOW',
      NO_TASKS: 'F.TIMELINE.NO_TASKS',
      START: 'F.TIMELINE.START',
    },
    TIME_TRACKING: {
      B: {
        ALREADY_DID: 'F.TIME_TRACKING.B.ALREADY_DID',
        SNOOZE: 'F.TIME_TRACKING.B.SNOOZE',
      },
      B_TTR: {
        ADD_TO_TASK: 'F.TIME_TRACKING.B_TTR.ADD_TO_TASK',
        MSG: 'F.TIME_TRACKING.B_TTR.MSG',
      },
      D_IDLE: {
        BREAK: 'F.TIME_TRACKING.D_IDLE.BREAK',
        CREATE_AND_TRACK: 'F.TIME_TRACKING.D_IDLE.CREATE_AND_TRACK',
        IDLE_FOR: 'F.TIME_TRACKING.D_IDLE.IDLE_FOR',
        SKIP: 'F.TIME_TRACKING.D_IDLE.SKIP',
        TASK: 'F.TIME_TRACKING.D_IDLE.TASK',
        TASK_BREAK: 'F.TIME_TRACKING.D_IDLE.TASK_BREAK',
        TRACK_TO: 'F.TIME_TRACKING.D_IDLE.TRACK_TO',
      },
      D_TRACKING_REMINDER: {
        CREATE_AND_TRACK: 'F.TIME_TRACKING.D_TRACKING_REMINDER.CREATE_AND_TRACK',
        IDLE_FOR: 'F.TIME_TRACKING.D_TRACKING_REMINDER.IDLE_FOR',
        TASK: 'F.TIME_TRACKING.D_TRACKING_REMINDER.TASK',
        TRACK_TO: 'F.TIME_TRACKING.D_TRACKING_REMINDER.TRACK_TO',
        UNTRACKED_TIME: 'F.TIME_TRACKING.D_TRACKING_REMINDER.UNTRACKED_TIME',
      },
    },
    WORKLOG: {
      CMP: {
        DAYS_WORKED: 'F.WORKLOG.CMP.DAYS_WORKED',
        MONTH_WORKED: 'F.WORKLOG.CMP.MONTH_WORKED',
        REPEATING_TASK: 'F.WORKLOG.CMP.REPEATING_TASK',
        RESTORE_TASK_FROM_ARCHIVE: 'F.WORKLOG.CMP.RESTORE_TASK_FROM_ARCHIVE',
        TASKS: 'F.WORKLOG.CMP.TASKS',
        TOTAL_TIME: 'F.WORKLOG.CMP.TOTAL_TIME',
        WEEK_NR: 'F.WORKLOG.CMP.WEEK_NR',
        WORKED: 'F.WORKLOG.CMP.WORKED',
      },
      D_CONFIRM_RESTORE: 'F.WORKLOG.D_CONFIRM_RESTORE',
      D_EXPORT_TITLE: 'F.WORKLOG.D_EXPORT_TITLE',
      D_EXPORT_TITLE_SINGLE: 'F.WORKLOG.D_EXPORT_TITLE_SINGLE',
      EXPORT: {
        ADD_COL: 'F.WORKLOG.EXPORT.ADD_COL',
        COPY_TO_CLIPBOARD: 'F.WORKLOG.EXPORT.COPY_TO_CLIPBOARD',
        DONT_ROUND: 'F.WORKLOG.EXPORT.DONT_ROUND',
        EDIT_COL: 'F.WORKLOG.EXPORT.EDIT_COL',
        GROUP_BY: 'F.WORKLOG.EXPORT.GROUP_BY',
        O: {
          DATE: 'F.WORKLOG.EXPORT.O.DATE',
          ENDED_WORKING: 'F.WORKLOG.EXPORT.O.ENDED_WORKING',
          ESTIMATE_AS_CLOCK: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_CLOCK',
          ESTIMATE_AS_MILLISECONDS: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_MILLISECONDS',
          ESTIMATE_AS_STRING: 'F.WORKLOG.EXPORT.O.ESTIMATE_AS_STRING',
          FULL_HALF_HOURS: 'F.WORKLOG.EXPORT.O.FULL_HALF_HOURS',
          FULL_HOURS: 'F.WORKLOG.EXPORT.O.FULL_HOURS',
          FULL_QUARTERS: 'F.WORKLOG.EXPORT.O.FULL_QUARTERS',
          NOTES: 'F.WORKLOG.EXPORT.O.NOTES',
          PARENT_TASK: 'F.WORKLOG.EXPORT.O.PARENT_TASK',
          PARENT_TASK_TITLES_ONLY: 'F.WORKLOG.EXPORT.O.PARENT_TASK_TITLES_ONLY',
          PROJECTS: 'F.WORKLOG.EXPORT.O.PROJECTS',
          STARTED_WORKING: 'F.WORKLOG.EXPORT.O.STARTED_WORKING',
          TAGS: 'F.WORKLOG.EXPORT.O.TAGS',
          TASK_SUBTASK: 'F.WORKLOG.EXPORT.O.TASK_SUBTASK',
          TIME_AS_CLOCK: 'F.WORKLOG.EXPORT.O.TIME_AS_CLOCK',
          TIME_AS_MILLISECONDS: 'F.WORKLOG.EXPORT.O.TIME_AS_MILLISECONDS',
          TIME_AS_STRING: 'F.WORKLOG.EXPORT.O.TIME_AS_STRING',
          TITLES_AND_SUB_TASK_TITLES: 'F.WORKLOG.EXPORT.O.TITLES_AND_SUB_TASK_TITLES',
          WORKLOG: 'F.WORKLOG.EXPORT.O.WORKLOG',
        },
        OPTIONS: 'F.WORKLOG.EXPORT.OPTIONS',
        ROUND_END_TIME_TO: 'F.WORKLOG.EXPORT.ROUND_END_TIME_TO',
        ROUND_START_TIME_TO: 'F.WORKLOG.EXPORT.ROUND_START_TIME_TO',
        ROUND_TIME_WORKED_TO: 'F.WORKLOG.EXPORT.ROUND_TIME_WORKED_TO',
        SAVE_TO_FILE: 'F.WORKLOG.EXPORT.SAVE_TO_FILE',
        SEPARATE_TASKS_BY: 'F.WORKLOG.EXPORT.SEPARATE_TASKS_BY',
        SHOW_AS_TEXT: 'F.WORKLOG.EXPORT.SHOW_AS_TEXT',
      },
      WEEK: {
        EXPORT: 'F.WORKLOG.WEEK.EXPORT',
        NO_DATA: 'F.WORKLOG.WEEK.NO_DATA',
        TITLE: 'F.WORKLOG.WEEK.TITLE',
      },
    },
    SEARCH_BAR: {
      TOO_MANY_RESULTS: 'F.SEARCH_BAR.TOO_MANY_RESULTS',
      PLACEHOLDER: 'F.SEARCH_BAR.PLACEHOLDER',
      PLACEHOLDER_ARCHIVED: 'F.SEARCH_BAR.PLACEHOLDER_ARCHIVED',
      INFO: 'F.SEARCH_BAR.INFO',
      INFO_ARCHIVED: 'F.SEARCH_BAR.INFO_ARCHIVED',
    },
  },
  FILE_IMEX: {
    EXPORT_DATA: 'FILE_IMEX.EXPORT_DATA',
    IMPORT_FROM_FILE: 'FILE_IMEX.IMPORT_FROM_FILE',
    S_ERR_INVALID_DATA: 'FILE_IMEX.S_ERR_INVALID_DATA',
  },
  G: {
    CANCEL: 'G.CANCEL',
    CLICK_TO_EDIT: 'G.CLICK_TO_EDIT',
    CLOSE: 'G.CLOSE',
    DELETE: 'G.DELETE',
    DISMISS: 'G.DISMISS',
    DO_IT: 'G.DO_IT',
    EDIT: 'G.EDIT',
    EXTENSION_INFO: 'G.EXTENSION_INFO',
    LOGIN: 'G.LOGIN',
    LOGOUT: 'G.LOGOUT',
    MINUTES: 'G.MINUTES',
    NEXT: 'G.NEXT',
    NONE: 'G.NONE',
    NO_CON: 'G.NO_CON',
    OK: 'G.OK',
    PREVIOUS: 'G.PREVIOUS',
    REMOVE: 'G.REMOVE',
    RESET: 'G.RESET',
    SAVE: 'G.SAVE',
    TITLE: 'G.TITLE',
    UNDO: 'G.UNDO',
    UPDATE: 'G.UPDATE',
    WITHOUT_PROJECT: 'G.WITHOUT_PROJECT',
    YESTERDAY: 'G.YESTERDAY',
  },
  GCF: {
    AUTO_BACKUPS: {
      HELP: 'GCF.AUTO_BACKUPS.HELP',
      LABEL_IS_ENABLED: 'GCF.AUTO_BACKUPS.LABEL_IS_ENABLED',
      LOCATION_INFO: 'GCF.AUTO_BACKUPS.LOCATION_INFO',
      TITLE: 'GCF.AUTO_BACKUPS.TITLE',
    },
    EVALUATION: {
      IS_HIDE_EVALUATION_SHEET: 'GCF.EVALUATION.IS_HIDE_EVALUATION_SHEET',
      TITLE: 'GCF.EVALUATION.TITLE',
    },
    IDLE: {
      HELP: 'GCF.IDLE.HELP',
      IS_ENABLE_IDLE_TIME_TRACKING: 'GCF.IDLE.IS_ENABLE_IDLE_TIME_TRACKING',
      IS_ONLY_OPEN_IDLE_WHEN_CURRENT_TASK: 'GCF.IDLE.IS_ONLY_OPEN_IDLE_WHEN_CURRENT_TASK',
      IS_UN_TRACKED_IDLE_RESETS_BREAK_TIMER:
        'GCF.IDLE.IS_UN_TRACKED_IDLE_RESETS_BREAK_TIMER',
      MIN_IDLE_TIME: 'GCF.IDLE.MIN_IDLE_TIME',
      TITLE: 'GCF.IDLE.TITLE',
    },
    IMEX: {
      HELP: 'GCF.IMEX.HELP',
      TITLE: 'GCF.IMEX.TITLE',
    },
    KEYBOARD: {
      ADD_NEW_NOTE: 'GCF.KEYBOARD.ADD_NEW_NOTE',
      ADD_NEW_TASK: 'GCF.KEYBOARD.ADD_NEW_TASK',
      APP_WIDE_SHORTCUTS: 'GCF.KEYBOARD.APP_WIDE_SHORTCUTS',
      COLLAPSE_SUB_TASKS: 'GCF.KEYBOARD.COLLAPSE_SUB_TASKS',
      EXPAND_SUB_TASKS: 'GCF.KEYBOARD.EXPAND_SUB_TASKS',
      GLOBAL_ADD_NOTE: 'GCF.KEYBOARD.GLOBAL_ADD_NOTE',
      GLOBAL_ADD_TASK: 'GCF.KEYBOARD.GLOBAL_ADD_TASK',
      GLOBAL_SHOW_HIDE: 'GCF.KEYBOARD.GLOBAL_SHOW_HIDE',
      GLOBAL_TOGGLE_TASK_START: 'GCF.KEYBOARD.GLOBAL_TOGGLE_TASK_START',
      GO_TO_DAILY_AGENDA: 'GCF.KEYBOARD.GO_TO_DAILY_AGENDA',
      GO_TO_FOCUS_MODE: 'GCF.KEYBOARD.GO_TO_FOCUS_MODE',
      GO_TO_SCHEDULED_VIEW: 'GCF.KEYBOARD.GO_TO_SCHEDULED_VIEW',
      GO_TO_SETTINGS: 'GCF.KEYBOARD.GO_TO_SETTINGS',
      GO_TO_TIMELINE: 'GCF.KEYBOARD.GO_TO_TIMELINE',
      GO_TO_WORK_VIEW: 'GCF.KEYBOARD.GO_TO_WORK_VIEW',
      HELP: 'GCF.KEYBOARD.HELP',
      SHOW_SEARCH_BAR: 'GCF.KEYBOARD.SHOW_SEARCH_BAR',
      MOVE_TASK_DOWN: 'GCF.KEYBOARD.MOVE_TASK_DOWN',
      MOVE_TASK_UP: 'GCF.KEYBOARD.MOVE_TASK_UP',
      MOVE_TO_BACKLOG: 'GCF.KEYBOARD.MOVE_TO_BACKLOG',
      MOVE_TO_TODAYS_TASKS: 'GCF.KEYBOARD.MOVE_TO_TODAYS_TASKS',
      OPEN_PROJECT_NOTES: 'GCF.KEYBOARD.OPEN_PROJECT_NOTES',
      SELECT_NEXT_TASK: 'GCF.KEYBOARD.SELECT_NEXT_TASK',
      SELECT_PREVIOUS_TASK: 'GCF.KEYBOARD.SELECT_PREVIOUS_TASK',
      SYSTEM_SHORTCUTS: 'GCF.KEYBOARD.SYSTEM_SHORTCUTS',
      TASK_ADD_SUB_TASK: 'GCF.KEYBOARD.TASK_ADD_SUB_TASK',
      TASK_DELETE: 'GCF.KEYBOARD.TASK_DELETE',
      TASK_EDIT_TAGS: 'GCF.KEYBOARD.TASK_EDIT_TAGS',
      TASK_EDIT_TITLE: 'GCF.KEYBOARD.TASK_EDIT_TITLE',
      TASK_MOVE_TO_PROJECT: 'GCF.KEYBOARD.TASK_MOVE_TO_PROJECT',
      TASK_OPEN_CONTEXT_MENU: 'GCF.KEYBOARD.TASK_OPEN_CONTEXT_MENU',
      TASK_OPEN_ESTIMATION_DIALOG: 'GCF.KEYBOARD.TASK_OPEN_ESTIMATION_DIALOG',
      TASK_SCHEDULE: 'GCF.KEYBOARD.TASK_SCHEDULE',
      TASK_SHORTCUTS: 'GCF.KEYBOARD.TASK_SHORTCUTS',
      TASK_SHORTCUTS_INFO: 'GCF.KEYBOARD.TASK_SHORTCUTS_INFO',
      TASK_TOGGLE_ADDITIONAL_INFO_OPEN: 'GCF.KEYBOARD.TASK_TOGGLE_ADDITIONAL_INFO_OPEN',
      TASK_TOGGLE_DONE: 'GCF.KEYBOARD.TASK_TOGGLE_DONE',
      TITLE: 'GCF.KEYBOARD.TITLE',
      TOGGLE_BACKLOG: 'GCF.KEYBOARD.TOGGLE_BACKLOG',
      TOGGLE_BOOKMARKS: 'GCF.KEYBOARD.TOGGLE_BOOKMARKS',
      TOGGLE_PLAY: 'GCF.KEYBOARD.TOGGLE_PLAY',
      TOGGLE_SIDE_NAV: 'GCF.KEYBOARD.TOGGLE_SIDE_NAV',
      ZOOM_DEFAULT: 'GCF.KEYBOARD.ZOOM_DEFAULT',
      ZOOM_IN: 'GCF.KEYBOARD.ZOOM_IN',
      ZOOM_OUT: 'GCF.KEYBOARD.ZOOM_OUT',
    },
    LANG: {
      AR: 'GCF.LANG.AR',
      DE: 'GCF.LANG.DE',
      EN: 'GCF.LANG.EN',
      ES: 'GCF.LANG.ES',
      FA: 'GCF.LANG.FA',
      FR: 'GCF.LANG.FR',
      IT: 'GCF.LANG.IT',
      JA: 'GCF.LANG.JA',
      KO: 'GCF.LANG.KO',
      LABEL: 'GCF.LANG.LABEL',
      NB: 'GCF.LANG.NB',
      NL: 'GCF.LANG.NL',
      PT: 'GCF.LANG.PT',
      RU: 'GCF.LANG.RU',
      TITLE: 'GCF.LANG.TITLE',
      TR: 'GCF.LANG.TR',
      ZH: 'GCF.LANG.ZH',
      ZH_TW: 'GCF.LANG.ZH_TW',
    },
    MISC: {
      DEFAULT_PROJECT: 'GCF.MISC.DEFAULT_PROJECT',
      FIRST_DAY_OF_WEEK: 'GCF.MISC.FIRST_DAY_OF_WEEK',
      HELP: 'GCF.MISC.HELP',
      IS_AUTO_ADD_WORKED_ON_TO_TODAY: 'GCF.MISC.IS_AUTO_ADD_WORKED_ON_TO_TODAY',
      IS_AUTO_MARK_PARENT_AS_DONE: 'GCF.MISC.IS_AUTO_MARK_PARENT_AS_DONE',
      IS_AUTO_START_NEXT_TASK: 'GCF.MISC.IS_AUTO_START_NEXT_TASK',
      IS_CONFIRM_BEFORE_EXIT: 'GCF.MISC.IS_CONFIRM_BEFORE_EXIT',
      IS_DARK_MODE: 'GCF.MISC.IS_DARK_MODE',
      IS_DISABLE_INITIAL_DIALOG: 'GCF.MISC.IS_DISABLE_INITIAL_DIALOG',
      IS_HIDE_NAV: 'GCF.MISC.IS_HIDE_NAV',
      IS_MINIMIZE_TO_TRAY: 'GCF.MISC.IS_MINIMIZE_TO_TRAY',
      IS_NOTIFY_WHEN_TIME_ESTIMATE_EXCEEDED:
        'GCF.MISC.IS_NOTIFY_WHEN_TIME_ESTIMATE_EXCEEDED',
      IS_TRAY_SHOW_CURRENT_TASK: 'GCF.MISC.IS_TRAY_SHOW_CURRENT_TASK',
      IS_TURN_OFF_MARKDOWN: 'GCF.MISC.IS_TURN_OFF_MARKDOWN',
      TASK_NOTES_TPL: 'GCF.MISC.TASK_NOTES_TPL',
      TITLE: 'GCF.MISC.TITLE',
    },
    POMODORO: {
      BREAK_DURATION: 'GCF.POMODORO.BREAK_DURATION',
      CYCLES_BEFORE_LONGER_BREAK: 'GCF.POMODORO.CYCLES_BEFORE_LONGER_BREAK',
      DURATION: 'GCF.POMODORO.DURATION',
      HELP: 'GCF.POMODORO.HELP',
      IS_ENABLED: 'GCF.POMODORO.IS_ENABLED',
      IS_MANUAL_CONTINUE: 'GCF.POMODORO.IS_MANUAL_CONTINUE',
      IS_PLAY_SOUND: 'GCF.POMODORO.IS_PLAY_SOUND',
      IS_PLAY_SOUND_AFTER_BREAK: 'GCF.POMODORO.IS_PLAY_SOUND_AFTER_BREAK',
      IS_PLAY_TICK: 'GCF.POMODORO.IS_PLAY_TICK',
      IS_STOP_TRACKING_ON_BREAK: 'GCF.POMODORO.IS_STOP_TRACKING_ON_BREAK',
      LONGER_BREAK_DURATION: 'GCF.POMODORO.LONGER_BREAK_DURATION',
      TITLE: 'GCF.POMODORO.TITLE',
    },
    SOUND: {
      DONE_SOUND: 'GCF.SOUND.DONE_SOUND',
      IS_INCREASE_DONE_PITCH: 'GCF.SOUND.IS_INCREASE_DONE_PITCH',
      IS_PLAY_DONE_SOUND: 'GCF.SOUND.IS_PLAY_DONE_SOUND',
      TITLE: 'GCF.SOUND.TITLE',
      VOLUME: 'GCF.SOUND.VOLUME',
    },
    TAKE_A_BREAK: {
      HELP: 'GCF.TAKE_A_BREAK.HELP',
      IS_ENABLED: 'GCF.TAKE_A_BREAK.IS_ENABLED',
      IS_FOCUS_WINDOW: 'GCF.TAKE_A_BREAK.IS_FOCUS_WINDOW',
      IS_LOCK_SCREEN: 'GCF.TAKE_A_BREAK.IS_LOCK_SCREEN',
      MESSAGE: 'GCF.TAKE_A_BREAK.MESSAGE',
      MIN_WORKING_TIME: 'GCF.TAKE_A_BREAK.MIN_WORKING_TIME',
      SNOOZE_TIME: 'GCF.TAKE_A_BREAK.SNOOZE_TIME',
      MOTIVATIONAL_IMG: 'GCF.TAKE_A_BREAK.MOTIVATIONAL_IMG',
      TITLE: 'GCF.TAKE_A_BREAK.TITLE',
    },
    TIMELINE: {
      HELP: 'GCF.TIMELINE.HELP',
      L_IS_WORK_START_END_ENABLED: 'GCF.TIMELINE.L_IS_WORK_START_END_ENABLED',
      L_WORK_END: 'GCF.TIMELINE.L_WORK_END',
      L_WORK_START: 'GCF.TIMELINE.L_WORK_START',
      TITLE: 'GCF.TIMELINE.TITLE',
      WORK_START_END_DESCRIPTION: 'GCF.TIMELINE.WORK_START_END_DESCRIPTION',
    },
    TRACKING_REMINDER: {
      HELP: 'GCF.TRACKING_REMINDER.HELP',
      L_IS_ENABLED: 'GCF.TRACKING_REMINDER.L_IS_ENABLED',
      L_IS_SHOW_ON_MOBILE: 'GCF.TRACKING_REMINDER.L_IS_SHOW_ON_MOBILE',
      L_MIN_TIME: 'GCF.TRACKING_REMINDER.L_MIN_TIME',
      TITLE: 'GCF.TRACKING_REMINDER.TITLE',
    },
  },
  GLOBAL_SNACK: {
    COPY_TO_CLIPPBOARD: 'GLOBAL_SNACK.COPY_TO_CLIPPBOARD',
    ERR_COMPRESSION: 'GLOBAL_SNACK.ERR_COMPRESSION',
    PERSISTENCE_DISALLOWED: 'GLOBAL_SNACK.PERSISTENCE_DISALLOWED',
    RUNNING_X: 'GLOBAL_SNACK.RUNNING_X',
    SHORTCUT_WARN_OPEN_BOOKMARKS_FROM_TAG:
      'GLOBAL_SNACK.SHORTCUT_WARN_OPEN_BOOKMARKS_FROM_TAG',
    SHORTCUT_WARN_OPEN_NOTES_FROM_TAG: 'GLOBAL_SNACK.SHORTCUT_WARN_OPEN_NOTES_FROM_TAG',
  },
  GPB: {
    ASSETS: 'GPB.ASSETS',
    DBX_DOWNLOAD: 'GPB.DBX_DOWNLOAD',
    DBX_GEN_TOKEN: 'GPB.DBX_GEN_TOKEN',
    DBX_META: 'GPB.DBX_META',
    DBX_UPLOAD: 'GPB.DBX_UPLOAD',
    GDRIVE_DOWNLOAD: 'GPB.GDRIVE_DOWNLOAD',
    GDRIVE_UPLOAD: 'GPB.GDRIVE_UPLOAD',
    GITHUB_LOAD_ISSUE: 'GPB.GITHUB_LOAD_ISSUE',
    JIRA_LOAD_ISSUE: 'GPB.JIRA_LOAD_ISSUE',
    UNKNOWN: 'GPB.UNKNOWN',
    WEB_DAV_DOWNLOAD: 'GPB.WEB_DAV_DOWNLOAD',
    WEB_DAV_UPLOAD: 'GPB.WEB_DAV_UPLOAD',
  },
  MH: {
    ADD_NEW_TASK: 'MH.ADD_NEW_TASK',
    CREATE_PROJECT: 'MH.CREATE_PROJECT',
    CREATE_TAG: 'MH.CREATE_TAG',
    DELETE_TAG: 'MH.DELETE_TAG',
    GO_TO_TASK_LIST: 'MH.GO_TO_TASK_LIST',
    MANAGE_PROJECTS: 'MH.MANAGE_PROJECTS',
    METRICS: 'MH.METRICS',
    NOTES: 'MH.NOTES',
    PROCRASTINATE: 'MH.PROCRASTINATE',
    PROJECTS: 'MH.PROJECTS',
    PROJECT_MENU: 'MH.PROJECT_MENU',
    PROJECT_SETTINGS: 'MH.PROJECT_SETTINGS',
    SCHEDULED: 'MH.SCHEDULED',
    SETTINGS: 'MH.SETTINGS',
    TAGS: 'MH.TAGS',
    TASKS: 'MH.TASKS',
    TASK_LIST: 'MH.TASK_LIST',
    TOGGLE_SHOW_BOOKMARKS: 'MH.TOGGLE_SHOW_BOOKMARKS',
    TOGGLE_SHOW_NOTES: 'MH.TOGGLE_SHOW_NOTES',
    TOGGLE_TRACK_TIME: 'MH.TOGGLE_TRACK_TIME',
    WORKLOG: 'MH.WORKLOG',
    SHOW_SEARCH_BAR: 'MH.SHOW_SEARCH_BAR',
  },
  PDS: {
    BACK: 'PDS.BACK',
    BREAK_LABEL: 'PDS.BREAK_LABEL',
    CELEBRATE: 'PDS.CELEBRATE',
    CLEAR_ALL_CONTINUE: 'PDS.CLEAR_ALL_CONTINUE',
    D_CONFIRM_APP_CLOSE: {
      CANCEL: 'PDS.D_CONFIRM_APP_CLOSE.CANCEL',
      MSG: 'PDS.D_CONFIRM_APP_CLOSE.MSG',
      OK: 'PDS.D_CONFIRM_APP_CLOSE.OK',
    },
    EVALUATION: 'PDS.EVALUATION',
    EXPORT_TASK_LIST: 'PDS.EXPORT_TASK_LIST',
    NO_TASKS: 'PDS.NO_TASKS',
    PLAN: 'PDS.PLAN',
    ROUND_15M: 'PDS.ROUND_15M',
    ROUND_30M: 'PDS.ROUND_30M',
    ROUND_5M: 'PDS.ROUND_5M',
    ROUND_TIME_SPENT: 'PDS.ROUND_TIME_SPENT',
    ROUND_TIME_SPENT_TITLE: 'PDS.ROUND_TIME_SPENT_TITLE',
    ROUND_TIME_WARNING: 'PDS.ROUND_TIME_WARNING',
    ROUND_UP_15M: 'PDS.ROUND_UP_15M',
    ROUND_UP_30M: 'PDS.ROUND_UP_30M',
    ROUND_UP_5M: 'PDS.ROUND_UP_5M',
    SAVE_AND_GO_HOME: 'PDS.SAVE_AND_GO_HOME',
    START_END: 'PDS.START_END',
    SUMMARY_FOR: 'PDS.SUMMARY_FOR',
    TASKS_COMPLETED: 'PDS.TASKS_COMPLETED',
    TASK_LIST: 'PDS.TASK_LIST',
    TIME_SPENT_AND_ESTIMATE_LABEL: 'PDS.TIME_SPENT_AND_ESTIMATE_LABEL',
    TIME_SPENT_ESTIMATE_TITLE: 'PDS.TIME_SPENT_ESTIMATE_TITLE',
    TODAY: 'PDS.TODAY',
    WEEK: 'PDS.WEEK',
  },
  PM: {
    TITLE: 'PM.TITLE',
  },
  PP: {
    ARCHIVED_PROJECTS: 'PP.ARCHIVED_PROJECTS',
    ARCHIVE_PROJECT: 'PP.ARCHIVE_PROJECT',
    CREATE_NEW: 'PP.CREATE_NEW',
    DELETE_PROJECT: 'PP.DELETE_PROJECT',
    D_CONFIRM_ARCHIVE: {
      MSG: 'PP.D_CONFIRM_ARCHIVE.MSG',
      OK: 'PP.D_CONFIRM_ARCHIVE.OK',
    },
    D_CONFIRM_DELETE: {
      MSG: 'PP.D_CONFIRM_DELETE.MSG',
      OK: 'PP.D_CONFIRM_DELETE.OK',
    },
    D_CONFIRM_UNARCHIVE: {
      MSG: 'PP.D_CONFIRM_UNARCHIVE.MSG',
      OK: 'PP.D_CONFIRM_UNARCHIVE.OK',
    },
    EDIT_PROJECT: 'PP.EDIT_PROJECT',
    EXPORT_PROJECT: 'PP.EXPORT_PROJECT',
    GITHUB_CONFIGURED: 'PP.GITHUB_CONFIGURED',
    GITLAB_CONFIGURED: 'PP.GITLAB_CONFIGURED',
    IMPORT_FROM_FILE: 'PP.IMPORT_FROM_FILE',
    JIRA_CONFIGURED: 'PP.JIRA_CONFIGURED',
    S_INVALID_JSON: 'PP.S_INVALID_JSON',
    TITLE: 'PP.TITLE',
    UN_ARCHIVE_PROJECT: 'PP.UN_ARCHIVE_PROJECT',
  },
  PS: {
    GLOBAL_SETTINGS: 'PS.GLOBAL_SETTINGS',
    ISSUE_INTEGRATION: 'PS.ISSUE_INTEGRATION',
    PRIVACY_POLICY: 'PS.PRIVACY_POLICY',
    PRODUCTIVITY_HELPER: 'PS.PRODUCTIVITY_HELPER',
    PROJECT_SETTINGS: 'PS.PROJECT_SETTINGS',
    PROVIDE_FEEDBACK: 'PS.PROVIDE_FEEDBACK',
    SYNC_EXPORT: 'PS.SYNC_EXPORT',
    TAG_SETTINGS: 'PS.TAG_SETTINGS',
    TOGGLE_DARK_MODE: 'PS.TOGGLE_DARK_MODE',
  },
  SCHEDULE: {
    NO_SCHEDULED: 'SCHEDULE.NO_SCHEDULED',
    START_TASK: 'SCHEDULE.START_TASK',
  },
  THEMES: {
    SELECT_THEME: 'THEMES.SELECT_THEME',
    amber: 'THEMES.amber',
    blue: 'THEMES.blue',
    'blue-grey': 'THEMES.blue-grey',
    cyan: 'THEMES.cyan',
    'deep-orange': 'THEMES.deep-orange',
    'deep-purple': 'THEMES.deep-purple',
    green: 'THEMES.green',
    indigo: 'THEMES.indigo',
    'light-blue': 'THEMES.light-blue',
    'light-green': 'THEMES.light-green',
    lime: 'THEMES.lime',
    pink: 'THEMES.pink',
    purple: 'THEMES.purple',
    teal: 'THEMES.teal',
    yellow: 'THEMES.yellow',
  },
  V: {
    E_1TO10: 'V.E_1TO10',
    E_DATETIME: 'V.E_DATETIME',
    E_MAX: 'V.E_MAX',
    E_MAX_LENGTH: 'V.E_MAX_LENGTH',
    E_MIN: 'V.E_MIN',
    E_MIN_LENGTH: 'V.E_MIN_LENGTH',
    E_PATTERN: 'V.E_PATTERN',
    E_REQUIRED: 'V.E_REQUIRED',
  },
  WW: {
    ADD_MORE: 'WW.ADD_MORE',
    ADD_SCHEDULED_FOR_TODAY: 'WW.ADD_SCHEDULED_FOR_TODAY',
    ADD_SCHEDULED_FOR_TOMORROW: 'WW.ADD_SCHEDULED_FOR_TOMORROW',
    ADD_SOME_TASKS: 'WW.ADD_SOME_TASKS',
    COMPLETED_TASKS: 'WW.COMPLETED_TASKS',
    ESTIMATE_REMAINING: 'WW.ESTIMATE_REMAINING',
    FINISH_DAY: 'WW.FINISH_DAY',
    HELP_PROCRASTINATION: 'WW.HELP_PROCRASTINATION',
    NO_COMPLETED_TASKS: 'WW.NO_COMPLETED_TASKS',
    NO_PLANNED_TASKS: 'WW.NO_PLANNED_TASKS',
    READY_TO_WORK: 'WW.READY_TO_WORK',
    RESET_BREAK_TIMER: 'WW.RESET_BREAK_TIMER',
    TIME_ESTIMATED: 'WW.TIME_ESTIMATED',
    WITHOUT_BREAK: 'WW.WITHOUT_BREAK',
    WORKING_TODAY: 'WW.WORKING_TODAY',
  },
};
export { T };
