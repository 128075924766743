<ng-container>
  <div *ngIf="task?.issueWasUpdated" @expand style="text-align: center">
    <button (click)="hideUpdates()" color="accent" mat-raised-button>
      {{T.F.GITHUB.ISSUE_CONTENT.MARK_AS_CHECKED|translate}}
    </button>
  </div>

  <div class="table-wrapper">
    <table class="issue-table">
      <tr>
        <th>{{T.F.GITHUB.ISSUE_CONTENT.SUMMARY|translate}}</th>
        <td>
          <a [href]="issue?.html_url" target="_blank"
            ><strong>{{issue?.title}} #{{issue?.number}}</strong></a
          >
        </td>
      </tr>
      <tr>
        <th>{{T.F.GITHUB.ISSUE_CONTENT.STATUS|translate}}</th>
        <td>{{issue?.state}}</td>
      </tr>
      <tr *ngIf="issue?.assignee?.html_url">
        <th>{{T.F.GITHUB.ISSUE_CONTENT.ASSIGNEE|translate}}</th>
        <td>
          <a [href]="issue?.assignee?.html_url" target="_blank"
            >{{issue?.assignee?.login}}</a
          >
        </td>
      </tr>
      <tr *ngIf="issue?.labels?.length">
        <th>{{T.F.GITHUB.ISSUE_CONTENT.LABELS|translate}}</th>
        <td>
          <mat-chip-list>
            <mat-chip
              *ngFor="let label of issue?.labels; trackBy: trackByIndex"
              [title]="label.description"
              >{{label.name}}
            </mat-chip>
          </mat-chip-list>
        </td>
      </tr>
      <tr *ngIf="issue?.body">
        <th>{{T.F.GITHUB.ISSUE_CONTENT.DESCRIPTION|translate}}</th>
        <td class="issue-description">
          <div [data]="issue?.body" class="description markdown" markdown></div>
        </td>
      </tr>
    </table>

    <div *ngIf="issue?.comments">
      <div
        *ngFor="let comment of (issue?.comments|sort:'created_at'); trackBy: trackByIndex"
        class="comment"
      >
        <!--<img [src]="comment.author.avatarUrl"-->
        <!--class="author-avatar">-->
        <div class="name-and-comment-content">
          <div>
            <span class="author-name">{{comment.user?.login}}</span>
            <span class="when"
              >{{T.F.GITHUB.ISSUE_CONTENT.AT|translate}}
              {{comment.created_at|date:'short'}}</span
            >
          </div>
          <div
            *ngIf="comment.body"
            [innerHTML]="comment?.body|markdown"
            class="markdown"
          ></div>
        </div>
      </div>
    </div>

    <div style="text-align: center">
      <a
        [href]="issue?.html_url"
        class="write-a-comment"
        color="primary"
        mat-stroked-button
        target="_blank"
      >
        <mat-icon>textsms</mat-icon>
        {{T.F.GITHUB.ISSUE_CONTENT.WRITE_A_COMMENT|translate}}
      </a>
    </div>
    <!--<pre><code>-->
    <!--{{issue?|json}}-->
    <!--</code></pre>-->
  </div>
</ng-container>
