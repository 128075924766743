<header>
  <button #buttonEl (click)="addNote()" id="add-note-btn" mat-button>
    <mat-icon>add</mat-icon>
    {{T.F.NOTE.NOTES_CMP.ADD_BTN|translate}}
  </button>
  <button (click)="onScrollToSidenavClick()" mat-icon-button>
    <mat-icon>arrow_upwards</mat-icon>
  </button>
  <!--  <button mat-icon-button>-->
  <!--    <mat-icon>search</mat-icon>-->
  <!--  </button>-->
</header>

<div *ngIf="isDragOver" @fade class="bgc-accent drag-over-msg">
  <mat-icon>add</mat-icon>
  {{T.F.NOTE.NOTES_CMP.DROP_TO_ADD|translate}}
</div>

<div
  [@standardList]="(noteService.notes$|async)?.length"
  [dragulaModel]="(noteService.notes$|async)"
  class="notes"
  dragula="NOTES"
>
  <note
    *ngFor="let note of (noteService.notes$|async); trackBy:trackById; let i = index;"
    [isFocus]="isElementWasAdded && i==0"
    [note]="note"
  ></note>
</div>
