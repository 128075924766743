<task-additional-info-wrapper
  *ngIf="!(workContextService.isContextChangingWithDelay$|async)"
  @projectChange
>
  <div #containerEl class="wrapper">
    <div #splitTopEl class="today">
      <header class="work-view-header">
        <div
          *ngIf="!(planningModeService.isPlanningMode$|async)"
          @fade
          class="status-bar"
        >
          <div class="item">
            <span class="label">{{T.WW.ESTIMATE_REMAINING|translate}}</span>
            <span class="no-wrap">
              ~<strong class="time-val"
                >{{workContextService.estimateRemainingToday$|async|msToString}}</strong
              >
              <mat-icon style="margin-left: 2px" svgIcon="estimate_remaining"></mat-icon>
            </span>
          </div>

          <div class="item">
            <span class="label">{{T.WW.WORKING_TODAY|translate}}</span>
            <span class="no-wrap">
              <strong class="time-val"
                >{{workContextService.workingToday$|async|msToString}}</strong
              >
              <mat-icon svgIcon="working_today"></mat-icon>
            </span>
          </div>

          <div *ngIf="isShowTimeWorkedWithoutBreak" class="item">
            <span class="label">{{T.WW.WITHOUT_BREAK|translate}} </span>
            <span class="no-wrap">
              <strong class="time-val"
                >{{takeABreakService.timeWorkingWithoutABreak$|async|msToString}}</strong
              ><mat-icon>timer</mat-icon>
            </span>
            <div
              [matTooltip]="T.WW.RESET_BREAK_TIMER|translate"
              class="take-a-break-reset-btn"
            >
              <button (click)="resetBreakTimer()" color="lighter" mat-mini-fab>
                <mat-icon>timer_off</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div
          *ngIf="(planningModeService.isPlanningMode$|async)"
          class="planning-mode-header"
        >
          <h3 *ngIf="!(undoneTasks?.length)" class="planning-mode-header mat-h3">
            {{T.WW.ADD_SOME_TASKS|translate}}
          </h3>
          <div *ngIf="undoneTasks?.length" class="status-bar">
            <div class="item">
              <span class="label">{{T.WW.TIME_ESTIMATED|translate}}</span>
              <span class="no-wrap">
                <strong class="time-val"
                  >~{{workContextService.estimateRemainingToday$|async|msToString}}</strong
                >
                <mat-icon>timer</mat-icon>
              </span>
            </div>
          </div>
        </div>
      </header>

      <improvement-banner
        *ngIf="(improvementService.hasLastTrackedImprovements$|async)"
        [@expandFade]
      ></improvement-banner>

      <div class="task-list-wrapper">
        <div
          *ngIf="!(planningModeService.isPlanningMode$|async) && !(workContextService.isHasTasksToWorkOn$|async)"
          @expandFade
          class="add-more-or-finish"
        >
          <h2>{{T.WW.NO_PLANNED_TASKS|translate}}</h2>

          <div class="btn-wrapper">
            <button (click)="planMore()" color="" mat-flat-button tabindex="1">
              <mat-icon>playlist_add</mat-icon>
              {{T.WW.ADD_MORE|translate}}
            </button>

            <button
              color="primary"
              mat-flat-button
              routerLink="/active/daily-summary"
              tabindex="1"
            >
              <mat-icon>done_all</mat-icon>
              {{T.WW.FINISH_DAY|translate}}
            </button>
          </div>
        </div>

        <div
          *ngIf="(planningModeService.isPlanningMode$|async)"
          @expandFade
          class="planning-mode-content"
        >
          <add-task-bar
            (done)="startWork()"
            [isAddToBottom]="true"
            [tabindex]="1"
            [isDoubleEnterMode]="true"
          ></add-task-bar>

          <!-- NOTE workContextService.isToday should work as we only need the initial value -->
          <ng-container
            *ngIf="workContextService.isToday && (taskService.allPlannedForTodayNotOnToday$|async) as plannedForToday"
          >
            <button
              *ngIf="plannedForToday?.length"
              (click)="addAllPlannedToToday(plannedForToday)"
              class="add-scheduled"
              color=""
              mat-stroked-button
              tabindex="1"
            >
              <mat-icon>playlist_add</mat-icon>
              {{T.WW.ADD_SCHEDULED_FOR_TODAY|translate:{nr: plannedForToday.length} }}
            </button>

            <ng-container
              *ngIf="plannedForToday.length === 0 &&(taskService.allPlannedForTomorrowNotOnToday$|async) as plannedForTomorrow"
            >
              <button
                *ngIf="plannedForTomorrow?.length"
                (click)="addAllPlannedToToday(plannedForTomorrow)"
                class="add-scheduled"
                color=""
                mat-stroked-button
                tabindex="1"
              >
                <mat-icon>playlist_add</mat-icon>
                {{T.WW.ADD_SCHEDULED_FOR_TOMORROW|translate:{nr:
                plannedForTomorrow.length} }}
              </button>
            </ng-container>
          </ng-container>

          <button
            (click)="startWork()"
            class="ready-to-work-btn"
            color="primary"
            mat-flat-button
            tabindex="1"
          >
            <mat-icon>check</mat-icon>
            {{T.WW.READY_TO_WORK|translate}}
          </button>
        </div>
        <task-list [tasks]="undoneTasks" listId="PARENT" listModelId="UNDONE"></task-list>

        <div
          *ngIf="!(planningModeService.isPlanningMode$|async) || (doneTasks)?.length"
          @expand
        >
          <h2 class="mat-h2 completed-tasks-heading">
            {{T.WW.COMPLETED_TASKS|translate}}
          </h2>
          <task-list
            [noTasksMsg]="T.WW.NO_COMPLETED_TASKS|translate"
            [tasks]="doneTasks"
            listId="PARENT"
            listModelId="DONE"
          ></task-list>
          <div class="finish-day-button-wrapper">
            <!-- NOTE: tabindex is same as for tasks -->
            <button
              color="primary"
              mat-flat-button
              routerLink="/active/daily-summary"
              tabindex="1"
            >
              <mat-icon>done_all</mat-icon>
              {{T.WW.FINISH_DAY|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      #splitBottomEl
      *ngIf="isShowBacklog"
      [style.user-focus]="(splitInputPos===100)? 'none':''"
      class="backlog"
    >
      <split
        (mouseenter)="isPreloadBacklog=true"
        (mouseleave)="isPreloadBacklog=false"
        (posChanged)="splitInputPos=$event"
        [containerEl]="containerEl"
        [counter]="backlogTasks?.length"
        [isAnimateBtn]="isTriggerBacklogIconAni$|async"
        [splitBottomEl]="splitBottomEl"
        [splitPos]="splitInputPos"
        [splitTopEl]="splitTopEl"
      ></split>
      <div
        *ngIf="isPreloadBacklog || splitInputPos!==100"
        class="inner-wrapper"
        style="user-select: none"
      >
        <backlog
          (closeBacklog)="splitInputPos=100"
          [backlogTasks]="backlogTasks"
        ></backlog>
      </div>
    </div>
  </div>
</task-additional-info-wrapper>

<button
  (click)="layoutService.showAddTaskBar()"
  [class.isRight]="isShowBacklog"
  [matTooltip]="T.MH.ADD_NEW_TASK|translate"
  class="show-xs-only add-task-btn"
  color="lighter"
  mat-fab
>
  <mat-icon>add</mat-icon>
</button>
