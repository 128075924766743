<div *ngIf="isHideDoneIN || isHideAllIN" @expandFadeFast class="done-task-box">
  <button (click)="expandDoneTasks()" class="expand-tasks-btn" mat-button>
    <!--TODO translate -->
    <em>+ {{isHideDoneIN ? doneTasksLength + ' done ' : allTasksLength}} sub tasks</em>
    <mat-icon>expand_more</mat-icon>
  </button>
</div>

<div
  #listEl
  [@taskList]="isBlockAni ? 'BLOCK' : filteredTasks?.length"
  [attr.data-id]="listModelId"
  [dragulaModel]="filteredTasks"
  [dragula]="listId"
  class="task-list-inner"
>
  <task
    *ngFor="let task of filteredTasks; trackBy: trackByFn; let i = index;"
    [isBacklog]="isBacklog"
    [task]="task"
  >
  </task>
</div>

<div *ngIf="noTasksMsg && !allTasksLength" class="no-tasks">{{noTasksMsg}}</div>
