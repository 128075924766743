<task-additional-info-wrapper *ngIf="timelineEntries$|async as timelineEntries">
  <div class="wrapper">
    <div class="inner-wrapper" [@standardList]="timelineEntries.length">
      <div *ngIf="!timelineEntries.length" class="no-tasks-msg">
        {{T.F.TIMELINE.NO_TASKS|translate}}
      </div>

      <div class="row" *ngFor="let row of timelineEntries; trackBy: trackByFn">
        <div class="col time" [class.hasTime]="!row.isHideTime">
          <div *ngIf="!row.isHideTime">
            <div>
              {{ row.start <= now ? (T.F.TIMELINE.NOW|translate) :
              (row.start|roundDuration|date:'HH:mm')}}
            </div>
            <!--            <div *ngIf="row.start>= tomorrow" class="date">-->
            <!--              {{ row.start <= now ? (T.F.TIMELINE.NOW|translate) :-->
            <!--              (row.start|date:'shortDate')}}-->
            <!--            </div>-->
          </div>
        </div>
        <div class="col right" [ngSwitch]="row.type">
          <task
            *ngSwitchCase="TimelineViewEntryType.Task"
            showParentTitle="yes"
            [task]="row.data"
          ></task>
          <task
            *ngSwitchCase="TimelineViewEntryType.ScheduledTask"
            showParentTitle="yes"
            [task]="row.data"
          ></task>
          <task
            *ngSwitchCase="TimelineViewEntryType.SplitTask"
            showParentTitle="yes"
            class="split-task"
            [task]="row.data"
          ></task>

          <div
            *ngSwitchCase="TimelineViewEntryType.SplitTaskContinuedLast"
            class="task-continued last"
          >
            <div class="title">{{row.data.title}} ({{row.data.index + 2}})</div>
            <div class="time-to-go">
              continued ~{{row.data.timeToGo|roundDuration|msToString}}
            </div>
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.SplitTaskContinued"
            class="task-continued"
          >
            <div class="title">{{row.data.title}} ({{row.data.index + 2}})</div>
            <div class="time-to-go">
              continued ~{{row.data.timeToGo|roundDuration|msToString}}
            </div>
          </div>

          <timeline-custom-event
            *ngSwitchCase="TimelineViewEntryType.CustomEvent"
            [event]="row.data"
          ></timeline-custom-event>

          <div
            *ngSwitchCase="TimelineViewEntryType.WorkdayStart"
            [title]="row.start|date:'medium'"
            class="work-start"
          >
            {{T.F.TIMELINE.START|translate}}
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.WorkdayEnd"
            [title]="row.start|date:'medium'"
            class="work-end"
          >
            {{T.F.TIMELINE.END|translate}}
          </div>

          <div
            *ngSwitchCase="TimelineViewEntryType.DayCrossing"
            class="day-crossing separator"
          >
            {{row.start|date:'d.M.'}} <span class="day">{{row.start|date:'(EEE)'}}</span>
          </div>

          <div *ngSwitchDefault>------------------</div>
        </div>
      </div>
    </div>
  </div>
</task-additional-info-wrapper>
